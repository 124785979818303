import React, { PureComponent } from 'react';
import SubscriptionEnum from '@dt/enums/SubscriptionEnum';
import { palette } from '@dt/theme';
import Badge from '../internal/Badge';

export default class SubscriptionBadge extends PureComponent {
  static defaultProps = {
    format: type => type,
  };

  render() {
    const { getSubscriptionBadgeColor, props } = this;
    const { type, format, fill, radius, size } = props;

    const label = format(type);
    const color = getSubscriptionBadgeColor(type);

    return (
      <Badge
        label={<div style={{ textAlign: 'center' }}>{label}</div>}
        backgroundColor={color}
        fill={fill}
        radius={radius}
        size={size}
      />
    );
  }

  getSubscriptionBadgeColor = subscriptionType => {
    return {
      [SubscriptionEnum.NO_SUBSCRIPTION]: palette.inacitveColor,
      [SubscriptionEnum.P1_ALERTS]: palette.brand,
      [SubscriptionEnum.STATIC]: palette.brand,
      [SubscriptionEnum.DYNAMIC]: palette.brand,
      [SubscriptionEnum.APP_LOGIC]: palette.brand,
    }[subscriptionType];
  };
}
