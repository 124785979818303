import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';

const api = config.horizonApiBaseUrl;
const version = 'v1';

export async function list() {
  return fetch(`${api}/${version}/openapi_definitions`).then(parse);
}

export async function create(params) {
  return fetch(`${api}/${version}/openapi_definitions`, {
    method: 'POST',
    body: params,
    headers: { 'Content-Type': 'text/plain' },
  }).then(parse);
}
