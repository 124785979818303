import React, { memo } from 'react';
// $FlowFixMe TS file
import TableFiltersAssetTagKeyList from './TableFiltersAssetTagKeyList';

const AssetFilterAssetTag = ({ selectedAssetTagsEncoded, onChange }) => {
  const selectedAssetTags = JSON.parse(selectedAssetTagsEncoded);

  const onToggleAssetTagKey = assetTagKey => {
    if (selectedAssetTags[assetTagKey] === null) {
      delete selectedAssetTags[assetTagKey];
      onChange(JSON.stringify(selectedAssetTags));
    } else {
      selectedAssetTags[assetTagKey] = null;
      onChange(JSON.stringify(selectedAssetTags));
    }
  };

  const onToggleAssetTagValue = (key, value) => {
    if (value) {
      if (selectedAssetTags[key]?.includes(value)) {
        const assetTagValueIdx = selectedAssetTags[key]?.indexOf(value);
        if (assetTagValueIdx > -1) {
          selectedAssetTags[key]?.splice(selectedAssetTags[key]?.indexOf(value), 1);
        }
        onChange(JSON.stringify(selectedAssetTags));
      } else {
        if (selectedAssetTags[key]?.length) {
          selectedAssetTags[key].push(value);
        } else {
          selectedAssetTags[key] = [value];
        }
        onChange(JSON.stringify(selectedAssetTags));
      }
    }
  };

  return (
    <TableFiltersAssetTagKeyList
      selectedAssetTags={selectedAssetTags}
      onToggleAssetTagKey={onToggleAssetTagKey}
      onToggleAssetTagValue={onToggleAssetTagValue}
    />
  );
};

export default memo(AssetFilterAssetTag);
