export const ActionEnum = {
  SET_MOBILE_APP_IDS_FILTER: 'filter/set-mobile-app-ids',
  SET_KEYWORDS_FILTER: 'filter/set-keywords',
  SET_STORE_BLOCKERS_FILTER: 'filter/set-store-blockers',
  SET_PRIORITY_FILTER: 'filter/set-priority',
  SET_SEVERITY_FILTER: 'filter/set-severity',
  SET_COMPLIANCE_POLICY_FILTER: 'filter/set-compliance-policy',
  SET_STATUS_FILTER: 'filter/set-status',
  SET_DATE_FILTER: 'filter/set-date',
  SET_DATE_FROM_FILTER: 'filter/set-date-from',
  SET_DATE_TO_FILTER: 'filter/set-date-to',
  SET_CURRENT_STATUS_FILTER: 'filter/set-current-status',
  SET_RELEASE_TYPE_FILTER: 'filter/set-release-type',
  SET_MIN_AGE_FILTER: 'filter/set-min-age',
  SET_FINDINGS_SORT: 'filter/set-findings-sort',
  TOGGLE_SHOWING: 'filter/toggle-showing',
  ADD_FILTER: 'filter/add',
  ADDED_FILTER: 'filters/add-success',
  REMOVE_FILTER: 'filter/remove',
  REMOVED_FILTER: 'filter/remove-success',
  SET_AS_CURRENT_FILTER: 'filter/set-as-current',
  RESET_FILTER: 'filter/reset',
  REQUEST_FILTERS_FROM_SERVER: 'filter/request-from-server',
  FILTERS_LOADED_FROM_SERVER: 'filter/loaded-from-server',
  OPEN_BUTTON_CLICKED: 'filter/search-open-clicked',
  SET_GLOBAL_FILTER: 'filter/set-global-filter',
  SAVE_REPORT_CLICKED: 'filter/save-report/clicked',
};

/** Used to type "options" for SelectField or MultiSelectField */

// Types

// Action Types

export const setKeywordsFilter = p => ({
  type: ActionEnum.SET_KEYWORDS_FILTER,
  payload: p,
});

export const setMobileAppIdsFilter = p => ({
  type: ActionEnum.SET_MOBILE_APP_IDS_FILTER,
  payload: p,
});

export const setPriorityFilter = p => ({
  type: ActionEnum.SET_PRIORITY_FILTER,
  payload: p,
});

export const setStoreBlockersFilter = p => ({
  type: ActionEnum.SET_STORE_BLOCKERS_FILTER,
  payload: p,
});

export const setSeverityFilter = s => ({
  type: ActionEnum.SET_SEVERITY_FILTER,
  payload: s,
});

export const setCompliancePolicyFilter = compliancePolicy => ({
  type: ActionEnum.SET_COMPLIANCE_POLICY_FILTER,
  payload: compliancePolicy,
});

export const setStatusFilter = s => ({
  type: ActionEnum.SET_STATUS_FILTER,
  payload: s,
});

export const setDateFilter = d => ({
  type: ActionEnum.SET_DATE_FILTER,
  payload: d,
});

export const setDateFromFilter = d => ({
  type: ActionEnum.SET_DATE_FROM_FILTER,
  payload: d,
});

export const setDateToFilter = d => ({
  type: ActionEnum.SET_DATE_TO_FILTER,
  payload: d,
});

export const setCurrentStatusFilter = s => ({
  type: ActionEnum.SET_CURRENT_STATUS_FILTER,
  payload: s,
});

export const setReleaseType = r => ({
  type: ActionEnum.SET_RELEASE_TYPE_FILTER,
  payload: r,
});

export const setMinAge = n => ({
  type: ActionEnum.SET_MIN_AGE_FILTER,
  payload: n,
});

export const setFindingsSort = s => ({
  type: ActionEnum.SET_FINDINGS_SORT,
  payload: s,
});

export const addFilter = (name, f) => ({
  type: ActionEnum.ADD_FILTER,
  payload: {
    name: name,
    value: f,
  },
});

export const removeFilter = i => ({
  type: ActionEnum.REMOVE_FILTER,
  payload: i,
});

export const addedFilter = (name, f) => ({
  type: ActionEnum.ADDED_FILTER,
  payload: {
    name: name,
    value: f,
  },
});

export const removedFilter = i => ({
  type: ActionEnum.REMOVED_FILTER,
  payload: i,
});

export const setGlobalFilter = (id, value) => ({
  type: ActionEnum.SET_GLOBAL_FILTER,
  payload: {
    id,
    value,
  },
});

export const setAsCurrentFilter = f => ({
  type: ActionEnum.SET_AS_CURRENT_FILTER,
  payload: f,
});

export const resetFilter = () => ({
  type: ActionEnum.RESET_FILTER,
  payload: null,
});

export const filtersLoadedFromServer = filters => ({
  type: ActionEnum.FILTERS_LOADED_FROM_SERVER,
  payload: filters,
});

export const requestFiltersFromServer = () => ({
  type: ActionEnum.REQUEST_FILTERS_FROM_SERVER,
  payload: null,
});

export const searchOpenClicked = () => ({
  type: ActionEnum.OPEN_BUTTON_CLICKED,
});

export const saveReportClicked = () => ({
  type: ActionEnum.SAVE_REPORT_CLICKED,
});
