import { values } from '@dt/functions';
import { sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { dateFormats } from '../../util/dateFormats';
import { formatDateDefault } from '../../util/formatDateDefault';
import { apiOperations } from '../api_operations/selectors';
import { policy_rules } from '../policy_rules/selectors';
import { policy_violations } from '../policy_violations/util';
import { getRestfulAPIsForInventory } from '../restful_apis/selectors';
import { restfulApis } from '../restful_apis/util';

export const getDetectInjectPolicyRuleTypeId = ({ detect_inject }) => detect_inject.targetRuleTypeUUID;

export const operationsToInject = ({ detect_inject }) => {
  const { includedOperations, excludedOperations } = detect_inject;
  return {
    includedOperations,
    excludedOperations,
  };
};

export const getDetectConfigs = ({ detect_inject }) => detect_inject.configuration;

export const getSQLInjectionPolicyRules = createSelector(
  policy_rules,
  getDetectInjectPolicyRuleTypeId,
  (policy_rules, getDetectInjectPolicyRuleTypeId) =>
    values(policy_rules.id).filter(policyRule => policyRule.policy_rule_type_id === getDetectInjectPolicyRuleTypeId),
);

export const isSQLInjectionPolicyEnabled = createSelector(getSQLInjectionPolicyRules, detectInjectRules =>
  Boolean(detectInjectRules.length),
);

export const getSqlInjectedPolicyViolations = createSelector(
  state => state.detect_inject.results,
  getSQLInjectionPolicyRules,
  policy_violations,
  (results, PolicyRules, policyViolations) => {
    return PolicyRules.reduce((all, PolicyRule) => {
      const policyViolationIds = policyViolations.for_violated_policy_rule_id[PolicyRule.id] || [];
      const list = policyViolationIds.reduce((list, policyViolatiovId) => {
        const policyViolation = policyViolations.id[policyViolatiovId];
        const operationId = policyViolation?.affected_api_operation_id;

        if (
          policyViolation &&
          operationId &&
          !policyViolation.date_resolved &&
          (!policyViolation.date_resolved ||
            (results.enabled &&
              results.date_completed &&
              formatDateDefault({ date: results.date_completed, formatStr: dateFormats.yyyyMMdd }) >=
                formatDateDefault({ date: String(policyViolation.date_resolved), formatStr: dateFormats.yyyyMMdd })))
        ) {
          list[operationId] = {
            date_created: policyViolation.date_created,
            id: policyViolation.id,
            additional_info: policyViolation.additional_info,
          };
        }
        return list;
      }, {});
      return { ...all, ...list };
    }, {});
  },
);

export const includedOperationList = createSelector(
  state => state.detect_inject.includedOperations,
  state => state.detect_inject.excludedOperations,
  getDetectConfigs,
  apiOperations,
  (includedOperations, excludedOperations, configs, operations) =>
    values(operations.id).reduce((list, operation) => {
      const { id, http_method } = operation;
      if (includedOperations.all) {
        return configs.method[http_method] ? list.concat(operation) : list;
      } else if (includedOperations.list[id] || !excludedOperations.list[id]) {
        return list.concat(operation);
      }
      return list;
    }, []),
);

export const excludedOperationList = createSelector(
  state => state.detect_inject.includedOperations,
  state => state.detect_inject.excludedOperations,
  getDetectConfigs,
  apiOperations,
  (includedOperations, excludedOperations, configs, operations) =>
    values(operations.id).reduce((list, operation) => {
      const { http_method, id } = operation;
      if (includedOperations.all) {
        return !configs.method[http_method] ? list.concat(id) : list;
      } else if (!includedOperations.list[id]) {
        return list.concat(id);
      }
      return list;
    }, []),
);

export const selectedOperationsCount = createSelector(
  state => state.detect_inject.includedOperations,
  includedOperations => {
    return includedOperations.all
      ? 'all'
      : values(includedOperations.list).reduce((count, isIncluded) => {
          return isIncluded ? count + 1 : count;
        }, 0);
  },
);

export const getDetectOperationList = createSelector(
  (state, props) => props.detectedCount,
  getDetectConfigs,
  apiOperations,
  restfulApis,
  (detectedCount, configs, operations, restfulApis) => ({
    rows: sortBy(
      values(operations.id).reduce((list, operation) => {
        if (detectedCount <= list.length) {
          return list;
        }
        const { http_method, id } = operation;
        const restfulApiId = restfulApis.for_operation_id[id];
        const restfulApi = restfulApiId && restfulApis.id[restfulApiId];
        return configs.method[http_method]
          ? list.concat({
              ...operation,
              base_url: restfulApi && restfulApi.base_url,
              base_path: restfulApi && restfulApi.base_path,
              title: restfulApi && restfulApi.title,
            })
          : list;
      }, []),
      ['base_url', 'base_path', 'path'],
    ),

    length: values(operations.id).filter(item => configs.method[item.http_method]).length,
  }),
);

export const getInjectOperationList = createSelector(
  includedOperationList,
  restfulApis,
  (includedOperations, restfulApis) => {
    return sortBy(
      includedOperations.reduce((list, operation) => {
        const { id } = operation;
        const restfulApiId = restfulApis.for_operation_id[id] || null;
        const restfulApi = (restfulApiId && restfulApis.id[restfulApiId]) || {};
        return list.concat({
          ...operation,
          base_url: restfulApi.base_url,
          base_path: restfulApi.base_path,
          title: restfulApi.title,
          randomOffset: Math.floor(Math.random() * 20),
        });
      }, []),
      ['base_url', 'base_path', 'path'],
    );
  },
);

export const getInjectionResults = createSelector(
  operationsToInject,
  getRestfulAPIsForInventory,
  getSqlInjectedPolicyViolations,
  (operationsToInject, restfulAPIs, sqlInjectedPolicyViolations) => {
    const { includedOperations, excludedOperations } = operationsToInject;
    return restfulAPIs.reduce((list, asset) => {
      const { api_operations, id: apiId, base_url, base_path } = asset;
      const selectOpsList = api_operations.reduce((operations, operation) => {
        const { id } = operation;
        if (includedOperations.all || includedOperations.list[id] || !excludedOperations.list[id]) {
          const vulnerable = sqlInjectedPolicyViolations[id]
            ? {
                unresolved: true,
                additional_info: sqlInjectedPolicyViolations[id].additional_info,
              }
            : {
                unresolved: false,
              };

          operations.push({
            ...operation,
            apiId,
            asset_type: 'operation',
            base_url,
            base_path,
            vulnerable,
          });
        }
        return operations;
      }, []);

      if (selectOpsList.length) {
        list.push({
          ...asset,
          api_operations: api_operations.reduce((operations, operation) => {
            const { id } = operation;
            if (includedOperations.all || includedOperations.list[id] || !excludedOperations.list[id]) {
              operations.push(operation);
            }
            return operations;
          }, []),
        });
        return list.concat(selectOpsList);
      }
      return list;
    }, []);
  },
);
