// sort-imports-ignore
import { Raven } from '@dt/global';
import React from 'react';
import { Button, Typography } from '@mui/material';

/*
 * Dynamically load a page component import to provide users only what they're asking for on
 * a page by page basis.
 *
 * Generated chunks aren't always one-to-one to their corresponding dynamic import.
 *
 * @param chunkImport - Dynamic import to handle errors and app upgrades for.
 */
const chunk = chunkImport => {
  return chunkImport
    .then(response => {
      // On succes - reset application update reload attempts.
      if (localStorage) {
        localStorage.setItem('applicationUpdateReloadAttempts', JSON.stringify(0));
      }

      return response;
    })
    .catch(e => {
      // User is most likely using an outdated version of the application.
      // Check to see if a new application version was released and attempt to automatically
      // reload.
      //
      // NOTE: This is only *okay* because we chunk by "page" meaning that
      //       when a user is navigating to a new page they might be looking for old files.
      //       So we manually "upgrade" them.
      //
      //       There are other better ways to fix this. But that implies changing our
      //       deployment pipelines and hosting provider.
      //
      //       Alternative Methods:
      //
      //       1. Keep the old application chunks around for a period of time.
      //          Which means keeping these chunks at the same deployment site.
      //
      //       2. Keep track of the application version and host these chunks in
      //          reliable locations that won't change when a new application is
      //          deployed. Using the version we could then reference which chunks
      //          to load for that version.
      //
      if (/Loading chunk [\d]+ failed/.test(e.message)) {
        // Attempt to reload the application automatically.
        if (localStorage) {
          const appUpdateReloadAttempts =
            JSON.parse(localStorage.getItem('applicationUpdateReloadAttempts') || '0') + 1;
          localStorage.setItem('applicationUpdateReloadAttempts', JSON.stringify(appUpdateReloadAttempts));

          // If this keeps happening something is wrong.
          if (appUpdateReloadAttempts >= 3) {
            Raven.captureException(new Error('User is unable to load application chunks.'), {});

            throw e;
          } else {
            window.location.reload();

            // Application updates are expected and therfore shouldn't error to the user.
            // It might be a better user experience to indicate to the user an upgrade needs
            // to happen.
            return {
              default: function () {
                return null;
              },
            };
          }
        } else {
          // Browser has `localStorage` disabled.
          return {
            default: function () {
              return (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Typography variant="body1" style={{ textAlign: 'center' }}>
                    A new version of the application is available. Please reload to continue.
                  </Typography>
                  <Button variant="contained" color="primary" onClick={() => window.location.reload()}>
                    Reload
                  </Button>
                </div>
              );
            },
          };
        }
      } else {
        throw e;
      }
    });
};

export default {
  chunk,
};
