import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { fromNowTime } from '@dt/date';
import { Link } from '@reach/router';
import PolicyViolationLabel from './PolicyViolationLabel';
import RelevanceTag from './RelevanceTag';
import Text from './Text';
import ComplianceTagStrip from './compliance-tag/ComplianceTagStrip';

const useStyles = makeStyles({
  card: {
    height: '100%',
  },
  cardContent: {
    paddingBottom: 16,
    '&:last-child': {
      paddingBottom: 16,
    },
  },
});

function PolicyViolationCardV2({
  relevance,
  title,
  id,
  status,
  date_resolved,
  exception_date_created,
  date_created,
  affected_asset_name,
  affected_asset_type_name,
  compliance_policy_references,
  link,
}) {
  const classes = useStyles();

  return (
    <Card classes={{ root: classes.card }}>
      <CardContent classes={{ root: classes.cardContent }}>
        <Link to={link} style={{ display: 'block' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <RelevanceTag relevance={relevance} size="medium" />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" component="div">
                        {title}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2} alignItems="center" justifyContent={'flex-end'}>
                    <Grid item>
                      <Text variant="body" component="div">
                        {`ID: ${id.substr(0, 8)}`}
                      </Text>
                    </Grid>
                    <Grid item>
                      <PolicyViolationLabel status={status} />
                    </Grid>
                    <Box component={Grid} item xs={12} display={{ xs: 'block', md: 'none', lg: 'none' }}>
                      <Typography variant="body2" component="div">
                        {date_resolved
                          ? `Resolved ${fromNowTime(date_resolved)}`
                          : exception_date_created
                            ? `Closed ${fromNowTime(date_created)}`
                            : `Caught ${fromNowTime(date_created)}`}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Typography variant="body2" component="div" className={classes.rowLeft}>
                        {affected_asset_type_name && affected_asset_name
                          ? `${affected_asset_type_name} at ${affected_asset_name}`
                          : 'Unknown Asset Affected'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2} alignItems="center" justifyContent={'flex-end'}>
                    <Grid item>
                      <Typography variant="body2" component="div">
                        {date_resolved
                          ? `Resolved ${fromNowTime(date_resolved)}`
                          : exception_date_created
                            ? `Closed ${fromNowTime(date_created)}`
                            : `Caught ${fromNowTime(date_created)}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                  {compliance_policy_references && (
                    <ComplianceTagStrip granular complianceReferences={compliance_policy_references} />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Link>
      </CardContent>
    </Card>
  );
}

export default React.memo(PolicyViolationCardV2);
