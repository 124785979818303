import { handleActions } from 'redux-actions';
import { createReducerCreator } from 'rezz';
import { setCacheStatus, setStatusesForKeyResourceFetch } from './actions';
import { StatusesTypeEnum } from './types';

const modifyStatatuses = (stateStatuses, { key, ...payload }) => {
  const statuses = new Map([...stateStatuses]);

  const currentStatus = statuses.get(key);

  const hasInitialLoaded = currentStatus ? currentStatus.initialLoaded : false;

  statuses.set(key, {
    isLoading: payload.status === StatusesTypeEnum.LOADING || false,
    error: payload.status === StatusesTypeEnum.ERROR ? payload.error : null,
    initialLoaded: payload.status === StatusesTypeEnum.DONE || hasInitialLoaded,
  });

  return statuses;
};

const modifyCacheStatus = (cache, { key, ...payload }) => {
  const cacheMap = new Map([...cache]);

  cacheMap.set(key, {
    startedAt: payload.start ? new Date() : null,
    endedAt: payload.done ? new Date() : null,
  });

  if (payload.error) {
    cacheMap.delete(key);
  }

  return cacheMap;
};

const initialState = {
  statuses: new Map([]),
  cache: new Map([]),
};

export default createReducerCreator({
  initialState: initialState,
})(
  handleActions(
    {
      [setStatusesForKeyResourceFetch.toString()]: (state, action) => ({
        ...state,
        statuses: modifyStatatuses(state.statuses, action.payload),
      }),
      [setCacheStatus.toString()]: (state, action) => ({
        ...state,
        cache: modifyCacheStatus(state.cache, action.payload),
      }),
    },
    initialState,
  ),
);
