import React, { memo } from 'react';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';

const useStyles = makeStyles({
  logoLine: {
    marginTop: 16,
    marginLeft: 16,
    marginRight: 16,
    marginBottom: 8,
  },

  menuInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  menuIconBox: {
    maxWidth: 40,
    maxHeight: 40,
    minWidth: 40,
    minHeight: 40,
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '16px',
  },

  menuText: {
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '&.title': {
      color: palette.white,
      fontSize: 20,
    },
  },
});

function ConfigurableLHSMenuHeader({ icon, name }) {
  const styles = useStyles();
  const DTProductIcon = icon;

  return (
    <div className={styles.logoLine}>
      <div className={styles.menuInner}>
        <div className={styles.menuIconBox}>
          {/* White icon */}
          <DTProductIcon />
        </div>
        <div className={`${styles.menuText} title`}>{name}</div>
      </div>
    </div>
  );
}

export default memo(ConfigurableLHSMenuHeader);
