var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Paper } from '@mui/material';
import styled from '@emotion/styled';
var StyledPaper = styled(Paper)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-radius: 8px;\n  padding: ", "px;\n  box-shadow: none;\n\n  &:hover {\n    box-shadow: ", ";\n  }\n"], ["\n  border-radius: 8px;\n  padding: ", "px;\n  box-shadow: none;\n\n  &:hover {\n    box-shadow: ", ";\n  }\n"])), function (props) { return (props.$noPadding ? 0 : 16); }, function (props) { return (props.$hover ? '0px 0px 8px rgba(0, 0, 0, 0.25)' : 'none'); });
var PaperContainer = function (_a) {
    var children = _a.children, _b = _a.style, style = _b === void 0 ? {} : _b, _c = _a.noPadding, noPadding = _c === void 0 ? false : _c, _d = _a.hover, hover = _d === void 0 ? false : _d;
    return (React.createElement(StyledPaper, { "$noPadding": noPadding, "$hover": hover, style: style }, children));
};
export default PaperContainer;
var templateObject_1;
