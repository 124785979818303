import React, { useCallback, useEffect, useMemo, useState } from 'react';
import tracking from 'react-tracking';
import InfoIcon from '@mui/icons-material/Info';
import { Button, Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';
import { dataCreators } from '@dt/analytics';
import copy from '@dt/components/src/copyToClipboard.util';
import { safeParse } from '@dt/functions';
import useCookie from '@dt/material-components/hooks/use_cookie';
import { palette } from '@dt/theme';
import { differenceInCalendarDays, isAfter } from 'date-fns';
import { dateFormats } from '../../apps/ahura/src/util/dateFormats';
import { formatDateDefault } from '../../apps/ahura/src/util/formatDateDefault';

const useStyles = makeStyles({
  alertRoot: {
    backgroundColor: palette.white,
    color: palette.gray20,
  },
  icon: { color: palette.blue },
  button: { color: palette.blue, marginRight: 8 },
});

export default tracking({})(function BroadcastMessage(props) {
  const classes = useStyles();

  const { message, hash, tracking, href, expires } = props;
  const [dismissed, setDismissed] = useState(false);

  const expiryDate = useMemo(() => {
    return formatDateDefault({ date: new Date(expires), formatStr: dateFormats.MMMddyyyy });
  }, [expires]);
  const daysToExpiry = useMemo(() => {
    return differenceInCalendarDays(expiryDate, new Date());
  }, [expiryDate]);

  const cookieKey = useMemo(() => {
    return hash ? `DT-${hash}` : '';
  }, [hash]);

  const [broadcastCookie, setBroadcastCookie] = useCookie(cookieKey);

  // No existing cookie for the current broadcast message
  useEffect(() => {
    if (typeof broadcastCookie === 'undefined') {
      const cookieValue = JSON.stringify({
        dismissed: false,
        expires: expiryDate,
      });

      daysToExpiry > 0
        ? setBroadcastCookie(cookieKey, cookieValue, { expires: daysToExpiry })
        : setBroadcastCookie(cookieKey, cookieValue);
    }
  }, [broadcastCookie, cookieKey, expires, expiryDate, setBroadcastCookie, daysToExpiry]);

  const handleBroadcastMessageLinkClick = useCallback(
    href => {
      if (!tracking) {
        throw new Error('Expected this component to have been decorated with tracking HoC');
      }

      tracking.trackEvent(dataCreators.broadCastMessageLinkClicked(href && href));
    },
    [tracking],
  );

  const broadcastCookieJSON = useMemo(() => {
    return safeParse(broadcastCookie);
  }, [broadcastCookie]);

  const dismissedOrExpiredCookie = useMemo(() => {
    return broadcastCookieJSON
      ? broadcastCookieJSON.dismissed || isAfter(formatDateDefault({ date: new Date() }), broadcastCookieJSON.expires)
      : false;
  }, [broadcastCookieJSON]);

  const handleDismissBroadcastMessage = useCallback(
    (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      if (!tracking) {
        throw new Error('Expected this component to have been decorated with tracking HoC');
      }

      setDismissed(true);

      // Update the cookie to reflect that the message should be permanently dismissed
      const cookieValue = JSON.stringify({
        dismissed: true,
        expires: broadcastCookie ? JSON.parse(broadcastCookie)['expires'] : 0,
      });

      setBroadcastCookie(cookieKey, cookieValue, {
        expires: daysToExpiry > 0 ? daysToExpiry : 0,
      });
      tracking.trackEvent(dataCreators.broadCastMessageDismissed());
    },
    [tracking, cookieKey, setBroadcastCookie, broadcastCookie, daysToExpiry],
  );

  if (dismissed || dismissedOrExpiredCookie || daysToExpiry <= 0) {
    return null;
  }

  if (!message) {
    return null;
  }

  const onCopy = () => {
    if (href) copy(href);
    return;
  };

  const actions = href ? (
    <div style={{ marginTop: 12 }}>
      <Button
        color="inherit"
        size="small"
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => handleBroadcastMessageLinkClick(href)}
        className={classes.button}
      >
        View
      </Button>
      <Button color="inherit" size="small" onClick={onCopy} className={classes.button}>
        Copy Link
      </Button>
    </div>
  ) : null;
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={!dismissed}
      onClose={(e, reason) => handleDismissBroadcastMessage(e, reason)}
    >
      <Alert
        elevation={6}
        onClose={handleDismissBroadcastMessage}
        severity="info"
        className={classes.alertRoot}
        icon={<InfoIcon className={classes.icon} />}
      >
        {message}
        {actions}
      </Alert>
    </Snackbar>
  );
});
