import { useEffect, useState } from 'react';

export default function useAssetTagEditValidation() {
  const [assetTags, setAssetTags] = useState([]);
  const [validations, setValidations] = useState([]);

  useEffect(() => {
    const findEmptyKeys = assetTags
      .filter(item => !item.tag.length && item.value?.length)
      .map(item => ({
        tag: item.tag,
        value: item.value,
        message: `Asset Key can't be empty`,
      }));

    const findDuplicateKeys = assetTags
      .filter(({ tag }, index) => assetTags.find((item, idx) => item.tag === tag && index !== idx))
      .map(item => ({
        tag: item.tag,
        value: item.value,
        message: `Asset Key can't be duplicated`,
      }));

    setValidations([...findEmptyKeys, ...findDuplicateKeys]);
  }, [assetTags]);

  return {
    set: setAssetTags,
    validations,
  };
}
