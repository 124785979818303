import React, { memo, useEffect, useState } from 'react';
import { Button, Grid, List, ListItem, Popover } from '@mui/material';
import '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';
import { useQuery } from '@dt/apollo-link-schema-rest';
import { ErrorState } from '@dt/components';
import asset_groups from '@dt/graphql-support/horizon/asset_groups';
import { Text } from '@dt/material-components';
import { palette } from '@dt/theme';

const useStyles = makeStyles(theme => ({
  popover: {
    marginTop: theme.spacing(1),
  },
  mainList: {
    width: 600,
    height: 400,
    overflow: 'scroll',
    overflowX: 'auto',
  },
  assetGroupList: {
    background: palette.white,
    padding: theme.spacing(0),
    '&:hover': {
      background: palette.gray50,
      '& > li': {
        background: palette.gray45,
      },
    },
  },
  removeItem: {
    background: palette.white,
    color: palette.gray20,
    '&:hover': {
      background: palette.gray45,
    },
  },
  assetGroupItem: {
    color: palette.gray20,
    paddingLeft: theme.spacing(4),
    '&:hover': {
      background: palette.gray45,
    },
  },
}));

const InventoryTableFilterComponent = function InventoryTableFilter({
  disabled,
  selectedAssetsCount,
  onMoveToAssetGroup,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const { data, loading, error, fetchMore } = useQuery(asset_groups.list);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Load all asset groups
  useEffect(() => {
    fetchMore && fetchMore();
  }, [fetchMore]);

  if (error) {
    return <ErrorState error={error.message} />;
  }

  if (loading) {
    return <Skeleton animation="wave" width={`100%`} height={35} />;
  }

  return (
    <>
      <Grid container spacing={2} alignItems={'center'}>
        <Grid item xs={9} md={6} container justifyContent={'flex-end'}>
          {selectedAssetsCount || 0} Selected
        </Grid>
        <Grid item xs={3} md={6} container justifyContent={'flex-end'}>
          <Button
            variant="contained"
            size="small"
            color={'primary'}
            disabled={disabled || !selectedAssetsCount}
            onClick={handleClick}
          >
            Actions
          </Button>
        </Grid>
      </Grid>

      <Popover
        id={'inventory-edit-action'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className={classes.popover}
      >
        <List component="nav" className={classes.mainList}>
          <ListItem dense>
            <Text color={palette.gray35}>Triaging {selectedAssetsCount} selected assets</Text>
          </ListItem>
          <ListItem
            button
            dense
            className={classes.removeItem}
            onClick={() => {
              setAnchorEl(null);
              onMoveToAssetGroup(null);
            }}
          >
            <Text color={palette.gray20}>Remove assets from all groups</Text>
          </ListItem>

          <List className={classes.assetGroupList}>
            <ListItem dense>
              <Text color={palette.gray20}>Move assets to group</Text>
            </ListItem>
            {data?.asset_group_list.asset_groups.map((assetGroup, key) => (
              <ListItem
                button
                dense
                key={key}
                className={classes.assetGroupItem}
                onClick={() => {
                  setAnchorEl(null);
                  onMoveToAssetGroup(assetGroup.id);
                }}
              >
                <Text noWrap>{assetGroup.name}</Text>
              </ListItem>
            ))}
          </List>
        </List>
      </Popover>
    </>
  );
};

export default memo(InventoryTableFilterComponent);
