import React, { PureComponent } from 'react';
import { getLatestStatusObject } from '@dt/findings/targets/status';
import { Markdown } from '@dt/material-components';
import PreviewInline from './../PreviewInline';
import CardStatusLine from './CardStatusLine';

export default class CardStatusLineForTarget extends PureComponent {
  render() {
    const { target, preview } = this.props;

    const statusobj = getLatestStatusObject(target);

    if (!statusobj) return null;
    const { status, date } = statusobj;

    const content = <Markdown inline text={target.formatted_text || 'The Mobile Application'} />;

    return (
      <CardStatusLine status={status} date={date}>
        {preview ? <PreviewInline>{content}</PreviewInline> : content}
      </CardStatusLine>
    );
  }
}
