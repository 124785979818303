import React, { PureComponent } from 'react';
import CardLine from './../CardLine';
import TargetStatus from './../TargetStatus';

export default class CardStatusLine extends PureComponent {
  render() {
    const { status, children, date } = this.props;

    if (!status) {
      return null;
    }

    return (
      <CardLine indicator={<TargetStatus status={status} />} date={date}>
        {children}
      </CardLine>
    );
  }
}
