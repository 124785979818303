import React, { memo, useMemo } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LaunchIcon from '@mui/icons-material/Launch';
import { Grid } from '@mui/material';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import {
  AssetTypeEnumValues,
  HostedOnValues,
  PolicyViolationExceptionValues,
  PolicyViolationStatusEnumValues,
  SpecialScanStatusValues,
} from '@dt/graphql-support/enums';
import { palette } from '@dt/theme';
import { addDays, differenceInDays, format } from 'date-fns';
import { dateFormats } from '../../../apps/ahura/src/util/dateFormats';
import { formatDateDefault } from '../../../apps/ahura/src/util/formatDateDefault';
import TagHostedOn from '../TagHostedOn';
import Text from '../Text';
import ToolkitAssetTableLastScanStatus from './ToolkitAssetTableLastScanStatus';
import ToolkitAssetTableLastTested from './ToolkitAssetTableLastTested';
import ToolkitTableSelectedRowDetails from './ToolkitAssetTableSelectedRowDetails';
import ToolkitTableSelectedRowScanProgress from './ToolkitAssetTableSelectedRowScanProgress';

const useStyles = makeStyles({
  row: props => ({
    backgroundColor: props.backgroundColor,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: palette.gray50,
    },
  }),
  cell: {
    padding: 8,
  },
  text: {
    maxWidth: 300,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  scanHistoryBlock: {
    borderRadius: 2,
    margin: 2,
    width: 14,
    minWidth: 14,
    maxWidth: 14,
    height: 14,
    minHeight: 14,
    maxHeight: 14,
    "&[data-color='red']": {
      background: palette.red30,
    },
    "&[data-color='green']": {
      background: palette.green30,
    },
    "&[data-color='gray']": {
      background: palette.gray35,
    },
  },
});

const assetTypeToPath = Object.freeze({
  [AssetTypeEnumValues.CLOUD_RESOURCE]: 'cloud-resources',
  [AssetTypeEnumValues.NETWORK_SERVICE]: 'network-services',
  [AssetTypeEnumValues.RESTFUL_API]: 'assets',
  [AssetTypeEnumValues.WEB_APPLICATION]: 'web-applications',
  [AssetTypeEnumValues.API_OPERATION]: 'api-operations',
  [AssetTypeEnumValues.GRPC_SERVICE]: 'assets',
  [AssetTypeEnumValues.GRPC_METHOD]: 'assets',
  [AssetTypeEnumValues.KUBERNETES_CLUSTER]: 'assets',
  [AssetTypeEnumValues.KUBERNETES_CLUSTER_COMPONENT]: 'assets',
  [AssetTypeEnumValues.SOAP_API]: 'assets',
  [AssetTypeEnumValues.SOAP_API_OPERATION]: 'assets',
  [AssetTypeEnumValues.REPOSITORY]: 'assets',
});

function ToolkitAssetTablePolicyViolationRow({
  asset,
  scans,
  policyViolationPath,
  portalPath,
  openRowId,
  onClick,
  isV2 = false,
}) {
  const isRowOpen = Boolean(asset.id === openRowId);

  const classes = useStyles({
    backgroundColor: isRowOpen ? palette.gray50 : null,
  });

  //Don't show red if asset was unprotected right from the start
  const hasPolicyViolationSinceCreationDate =
    differenceInDays(new Date(asset.date_created), new Date(asset.affected_asset.date_created)) <= 2;

  const scanHistory = useMemo(
    () =>
      scans
        ?.slice(0, 5)
        .filter(
          scan =>
            formatDateDefault({ date: scan.date_created }) >=
            formatDateDefault({ date: asset.affected_asset.date_created }),
        )
        .map(scan => {
          const isUnprotected = Boolean(
            asset.violated_policy_rule &&
              formatDateDefault({ date: asset.date_created }) <= formatDateDefault({ date: scan.date_created }) &&
              (!asset.date_resolved ||
                formatDateDefault({ date: asset.date_resolved }) >= formatDateDefault({ date: scan.date_created })),
          );

          const hasWontFixPolicyViolations = Boolean(
            asset.violated_policy_rule &&
              formatDateDefault({ date: asset.date_created }) <= formatDateDefault({ date: scan.date_created }) &&
              asset.exception_type === PolicyViolationExceptionValues.WONT_FIX &&
              asset.exception_date_created &&
              formatDateDefault({ date: asset.exception_date_created }) <=
                formatDateDefault({ date: scan.date_created }),
          );

          if (hasWontFixPolicyViolations || hasPolicyViolationSinceCreationDate) {
            return {
              description: `${formatDateDefault(scan.date_created, dateFormats.yyyyMMdd)} | By Design`,
              color: 'gray',
            };
          }

          if (isUnprotected) {
            return {
              description: `${formatDateDefault(scan.date_created, dateFormats.yyyyMMdd)} | Unprotected`,
              color: 'red',
            };
          }

          return {
            description: `${formatDateDefault(scan.date_created, dateFormats.yyyyMMdd)} | Protected`,
            color: 'green',
          };
        }),
    [scans, asset, hasPolicyViolationSinceCreationDate],
  );

  /**
   * there is a status history show scan stats (protected | unprotected ) for last five scan days
   * since we are showing resolved policy violations on table
   * this logic make the first status history block red (unprotected)
   * if resolved day is before five days ago ( last five scan days )
   */
  if (
    asset.date_resolved &&
    formatDateDefault({ date: asset.date_resolved }) <= formatDateDefault({ date: addDays(new Date(), -5) })
  ) {
    scanHistory[0].color = 'red';
    scanHistory[0].description = `${formatDateDefault({
      date: asset.date_resolved,
      formatStr: dateFormats.yyyyMMdd,
    })} | Unprotected`;
  }

  /**
   *  make first block red if exception_type is won't fix and
   * 'exception created date' is grater than 'created date'
   *  that means asset was unprotected in some point and
   *  later it turned to won't fix status
   */
  if (
    asset.exception_type === PolicyViolationExceptionValues.WONT_FIX &&
    asset.exception_date_created &&
    formatDateDefault({ date: asset.exception_date_created }) > formatDateDefault({ date: asset.date_created })
  ) {
    scanHistory[0].color = 'red';
    scanHistory[0].description = `${formatDateDefault({
      date: asset.exception_date_created,
      formatStr: dateFormats.yyyyMMdd,
    })} | Unprotected`;
  }

  const lastScanStatus =
    (asset.exception_type === PolicyViolationExceptionValues.WONT_FIX &&
      asset.exception_date_created &&
      formatDateDefault({ date: asset.exception_date_created }) <= formatDateDefault({ date: new Date() })) ||
    hasPolicyViolationSinceCreationDate
      ? 'By Design'
      : asset.date_resolved &&
          formatDateDefault({ date: asset.date_resolved }) <= formatDateDefault({ date: new Date() })
        ? 'Protected'
        : 'Unprotected';

  const authScheme = asset?.affected_asset.api_operation_authentication_scheme ?? '';

  return (
    <>
      <TableRow classes={{ root: classes.row }} onClick={() => onClick(asset)}>
        <TableCell classes={{ root: classes.cell }}>
          <Grid container spacing={1} alignItems="flex-start" alignContent="flex-start">
            <Grid item xs={6}>
              <Grid container justifyContent="flex-start">
                {isRowOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              {asset.affected_asset.id && (
                <Grid container justifyContent="center" alignItems="center" alignContent="center">
                  <a
                    href={`${portalPath}/${isV2 ? 'v2/' : ''}${assetTypeToPath[asset.affected_asset.asset_type]}/${
                      asset.affected_asset.id
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Text variant={'link'}>
                      <LaunchIcon />
                    </Text>
                  </a>
                </Grid>
              )}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="left" classes={{ root: classes.cell }}>
          <Box display="flex" alignItems="center">
            <TagHostedOn hosted_on={asset.affected_asset.hosted_on || HostedOnValues.UNKNOWN} />
            <Text variant="body" component="div" className={classes.text}>
              {asset.affected_asset.asset_type_name} at {asset.affected_asset.name}
            </Text>
          </Box>
        </TableCell>
        <TableCell align="left" classes={{ root: classes.cell }}>
          <Grid container justifyContent="flex-start">
            {scans[scans.length - 1].current_status === SpecialScanStatusValues.ONGOING
              ? '-'
              : scanHistory?.map(({ description, color }, key) => (
                  <Grid item key={key}>
                    <Tooltip disableInteractive title={description} aria-label={description}>
                      <div className={classes.scanHistoryBlock} data-color={color}></div>
                    </Tooltip>
                  </Grid>
                ))}
          </Grid>
        </TableCell>

        <TableCell align="left" classes={{ root: classes.cell }}>
          <Text
            variant="code"
            style={{
              color: authScheme === 'Basic Auth' ? palette.red : 'initial',
            }}
          >
            {authScheme}
          </Text>
        </TableCell>

        <TableCell align="center" classes={{ root: classes.cell }}>
          <Box display="flex" justifyContent="center">
            {scans[scans.length - 1].current_status === SpecialScanStatusValues.FINISHED ? (
              <ToolkitAssetTableLastScanStatus lastScanStatus={lastScanStatus} />
            ) : scans[scans.length - 1].current_status === SpecialScanStatusValues.ONGOING ? (
              <ToolkitTableSelectedRowScanProgress offset={Math.floor(Math.random() * 20)} />
            ) : (
              '-'
            )}
            <ToolkitAssetTableLastTested
              lastScanStatus={lastScanStatus}
              discoveredDate={asset.date_created}
              lastScanDateCreated={scans[scans.length - 1].date_created}
            />
          </Box>
        </TableCell>
      </TableRow>
      {isRowOpen &&
        asset.status !== PolicyViolationStatusEnumValues.RESOLVED &&
        asset.exception_type !== PolicyViolationExceptionValues.WONT_FIX &&
        !hasPolicyViolationSinceCreationDate && (
          <ToolkitTableSelectedRowDetails
            policyViolationId={asset.id}
            PolicyRuleType={asset.violated_policy_rule.policy_rule_type}
            portalPath={policyViolationPath}
          />
        )}
    </>
  );
}

export default memo(ToolkitAssetTablePolicyViolationRow);
