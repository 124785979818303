import React, { PureComponent } from 'react';
import { SecurityFindingCard } from './../security-finding-card';

function getLengthValueForFakeness(obj) {
  return 10 + (JSON.stringify(obj).length % 20) * 3;
}

function getFakeString(obj) {
  const length = getLengthValueForFakeness(obj);
  let retval = '';

  while (retval.length < length) {
    let char = '';
    while (!char.match(/[A-Za-z]/g)) {
      char = String.fromCharCode(Math.floor(Math.random() * 53) + 65);
    }
    retval += Math.random() * 10 < 2 ? ' ' : char;
  }

  return retval;
}

function getFakeTitle(finding) {
  return getFakeString(finding);
}

function getFakeTargetText(finding) {
  return [
    'sjsdj dsf weiokjewr 23',
    'dsfuj as91209 sdf9023 9 0f90329032 3290',
    'i ios `dsf.fdsfds.sdjhgfjhg.ewr54`',
    '`897234027698234074`',
    '`skdfsdjkfn` sdfnksjdn fsdfnk sdkfjns d `ekdjnkdfjg`',
    'h iufdsi isdfk sdbnf `sdjn.dfskjndsfsdf.sdf6yrusdf`',
    'nsjdk fkdsjnkjn dsfvn `jnd` jnkjdfg',
    '`fdj.4387987.msdlaso9ppa.oaoidpo.zxioz.soiujaoi`',
    '`sopij.aaiosjijasd.asdifdgjfdgh910nzx879.zxcuhiuzc`',
    '`dsiu.dsfsdf.fdhazxcb` sdik jknsdkj',
  ][JSON.stringify(finding).length % 10];
}

export default class ShadowScanPreviewFindingCard extends PureComponent {
  render() {
    const { finding } = this.props;
    const {
      id,
      aggregated_status,
      aggregated_status_date,
      compliance_policy_references,
      severity,
      priority,
      date_created,
      date_updated,
    } = finding;
    return (
      <SecurityFindingCard
        title={getFakeTitle(finding)}
        targets={[
          {
            security_finding_id: id,
            id: `targetid${id || ''}`,
            formatted_text: getFakeTargetText(finding),
            date_created: date_created,
            date_updated: date_updated,
            statuses: [
              {
                date: aggregated_status_date || '',
                status: aggregated_status || 'NEW',
              },
            ],
            additional_contexts: [],
          },
        ]}
        id={id}
        numberOfComments={0}
        priority={priority}
        severity={severity}
        hasMediawatchArticles={false}
        aggregated_status={aggregated_status}
        compliance_policy_references={compliance_policy_references || []}
        app_protection_score={null}
        preview
        app_info={{ loading: true }}
      />
    );
  }
}
