import React, { useCallback, useEffect, useState } from 'react';
import FilterIcon from '@mui/icons-material/FilterList';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Button,
  Card,
  ClickAwayListener,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Popper,
} from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { Text } from '@dt/material-components';
import { palette } from '@dt/theme';

const useStyles = makeStyles(() => ({
  popper: {
    // zIndex set here to be above the LHS menu of 1200.
    zIndex: 1201,
    marginTop: 8,
    marginBottom: 8,
  },
  popperContent: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 700,
    minHeight: 400,
    maxWidth: 700,
    maxHeight: 400,
  },
  navigation: {
    height: '100%',
    maxHeight: 345,
    padding: 0,
    boxShadow: 'inset -1px -1px 0px #e4e7eb',
    '& > li': {
      cursor: 'pointer',
    },
    overflowY: 'auto',
  },
  navigationContent: {
    height: '100%',
    padding: 8,
    boxShadow: 'inset 0px -1px 0px #e4e7eb',
    maxHeight: 345,
    overflowY: 'auto',
  },
}));

/*
 * Customizable popout filter.
 * Used to group multiple filters in one popout component.
 *
 * @param isOpen - Whether or not the filter is open.
 * @param filters - Filters to show.
 * @param onOpen - Triggered when the user wants to open the filter.
 * @param onClose - Triggered when the user wants to close the filter.
 * @param onApplyFilters - Triggered when the user applys filters.
 */
export const PopoutFilter = ({ isOpen, filters, onOpen, onClose, onApplyFilters }) => {
  const styles = useStyles();

  const [popperAnchor, setPopperAnchor] = useState(null);
  const [selectedItem, setSelectedItem] = useState(filters[0].name);

  const selectedFilter = filters.find(({ name }) => name === selectedItem);

  // Event Handlers.
  const handleOnClose = useCallback(() => {
    setSelectedItem(filters[0].name);
    setPopperAnchor(null);
    onClose();
  }, [filters, onClose]);
  const handleOnKeyDown = useCallback(
    ({ keyCode }) => {
      // Close when escape key is pressed.
      if (keyCode === 27) {
        handleOnClose();
      }
    },
    [handleOnClose],
  );

  // Bind document keydown for keyboard navigation.
  useEffect(() => {
    document.addEventListener('keydown', handleOnKeyDown);
    return () => {
      document.removeEventListener('keydown', handleOnKeyDown);
    };
  }, [handleOnKeyDown]);

  return (
    <>
      <Button
        variant="contained"
        size="small"
        startIcon={<FilterIcon style={{ fontSize: 16 }} />}
        onClick={event => {
          setPopperAnchor(event.currentTarget);
          onOpen();
        }}
        style={{
          height: 24,
          paddingTop: 4,
          paddingBottom: 4,
          paddingLeft: 12,
          paddingRight: 12,
        }}
      >
        <Text
          variant="button"
          color={palette.gray20}
          style={{
            lineHeight: 1.0,
            transform: 'translateY(1px)',
          }}
        >
          Filters
        </Text>
      </Button>

      {/* Popper */}
      <Popper
        open={Boolean(popperAnchor) && isOpen}
        anchorEl={popperAnchor}
        placement={'bottom-start'}
        className={styles.popper}
      >
        <ClickAwayListener onClickAway={handleOnClose}>
          <Card className={styles.popperContent}>
            {/* Filtering Section */}
            <Box display="flex" flexGrow={1} flexShrink={1} flexBasis="auto">
              <Grid container direction="row">
                {/* Left Side - Name */}
                <Grid item xs={5}>
                  <List component="nav" className={styles.navigation}>
                    {/* Filters */}
                    {filters.map(filter => (
                      <ListItem
                        tabIndex={0}
                        key={filter.name}
                        selected={selectedItem === filter.name}
                        onClick={() => {
                          !filter.disabled && setSelectedItem(filter.name);
                        }}
                        onKeyDown={({ keyCode }) => {
                          // Click when enter/space key is pressed.
                          if (keyCode === 13 || keyCode === 32) {
                            !filter.disabled && setSelectedItem(filter.name);
                          }
                        }}
                        disabled={filter.disabled}
                      >
                        <ListItemText primary={filter.name} />
                        <ListItemSecondaryAction>
                          <ArrowRightIcon />
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </Grid>

                {/* Right Side - Plugin */}
                <Grid item xs={7}>
                  <Box className={styles.navigationContent}>{selectedFilter && selectedFilter.plugin}</Box>
                </Grid>
              </Grid>
            </Box>

            {/* Filtering Actions */}
            <Box display="flex" justifyContent="flex-end">
              <Box m={1}>
                <Button variant="text" onClick={handleOnClose}>
                  Cancel
                </Button>
              </Box>

              <Box m={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleOnClose();
                    onApplyFilters();
                  }}
                >
                  Apply Filters
                </Button>
              </Box>
            </Box>
          </Card>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
