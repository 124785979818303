import React from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Collapse, List, ListItem, ListItemText, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { palette } from '@dt/theme';

const ellipsis = {
  minHeight: 25,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

const getStyles = () => ({
  ListItem: {
    ...ellipsis,
    paddingTop: ({ hasSubMenu }) => (hasSubMenu ? 0 : 'initial'),
    marginBottom: ({ isChildApp, hasSubMenu }) => (isChildApp || hasSubMenu ? 'initial' : 5),
    paddingBottom: ({ hasSubMenu }) => (hasSubMenu ? 0 : 'initial'),
    marginLeft: ({ isChildApp, isSubMenu }) => (isChildApp || isSubMenu ? 20 : 'initial'),
    borderLeft: ({ active }) => `4px solid ${active ? palette.blue : 'transparent'}`,
    backgroundColor: ({ active }) => (active ? palette.gray50 : 'transparent'),
    fontWeight: ({ heading }) => (heading ? '400 !important' : 'initial'),
    color: `${palette.gray10} !important`,
    width: ({ isSubMenu }) => (isSubMenu ? 170 : '100%'),
    '&:hover': {
      backgroundColor: palette.gray50,
    },
  },
  ListItemText: {
    ...ellipsis,
    color: palette.gray10,
    maxWidth: 180,
    fontWeight: ({ heading }) => (heading ? '400 !important' : 'lighter'),
    paddingLeft: ({ isChildApp }) => (isChildApp ? 10 : 'initial'),
  },
});

export default withStyles(getStyles)(function Item({
  children,
  open,
  onClick,
  disabled,
  badge,
  label,
  tooltip = false,
  noChevron,
  classes,
  hasSubMenu,
  isSubMenu,
  hasChildrenApps,
  isChildApp,
}) {
  const renderExpansionState = () => {
    if (hasSubMenu && !hasChildrenApps) {
      return null;
    }

    if (open && !noChevron && children) {
      return <ExpandLess />;
    }

    if (!open && !noChevron && children) {
      return <ExpandMore />;
    }

    if (!hasChildrenApps) {
      return null;
    }
  };

  if (isChildApp || isSubMenu) {
    return (
      <ListItem
        dense
        className={classes.ListItem}
        button={!disabled && !isSubMenu && !hasSubMenu}
        onClick={disabled ? null : onClick}
      >
        {!tooltip ? (
          <ListItemText
            primary={label}
            classes={{
              root: classes.ListItemText,
              primary: classes.ListItemText,
            }}
          />
        ) : (
          <Tooltip disableInteractive title={label}>
            <ListItemText
              primary={label}
              classes={{
                root: classes.ListItemText,
                primary: classes.ListItemText,
              }}
            />
          </Tooltip>
        )}
        {renderExpansionState()}
        {badge}
      </ListItem>
    );
  }

  return (
    <>
      <ListItem
        dense={!(isSubMenu || hasSubMenu)}
        classes={{ root: classes.ListItem }}
        button={!disabled && !isSubMenu && !hasSubMenu}
        onClick={disabled ? null : onClick}
      >
        {!tooltip ? (
          <ListItemText
            primary={label}
            classes={{
              root: classes.ListItemText,
              primary: classes.ListItemText,
            }}
          />
        ) : (
          <Tooltip disableInteractive title={label}>
            <ListItemText
              primary={label}
              classes={{
                root: classes.ListItemText,
                primary: classes.ListItemText,
              }}
            />
          </Tooltip>
        )}
        {renderExpansionState()}
        {badge}
      </ListItem>
      {children && (
        <Collapse in={Boolean(open)} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children}
          </List>
        </Collapse>
      )}
    </>
  );
});
