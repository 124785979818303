// sort-imports-ignore
import { Raven } from '@dt/global';
import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { palette } from '@dt/theme';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import PageLayout from '../PageLayout';
import PageTitle from '../PageTitle';
import Button from '@mui/material/Button';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paragraph: {
    margin: '5px 0 !important',
    maxWidth: 400,
  },
  icon: {
    width: 120,
    height: 120,
    background: palette.yellow50,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 0,
    flexShrink: 0,
    marginBottom: 15,
  },
});

export default React.memo(function ErrorState({ children, ...props }) {
  const classes = useStyles();
  const errorState = (
    <div className={classes.container}>
      <div className={classes.icon}>
        <OfflineBoltIcon style={{ width: 70, height: 70, color: palette.yellow10 }} />
      </div>
      <div style={{ textAlign: 'center' }}>
        <Typography variant="h5" style={{ color: palette.gray20 }}>
          {props.heading ? props.heading : 'Oh Snap!'}
        </Typography>
        <Typography variant="body2" className={classes.paragraph}>
          {props.paragraph ? (
            props.paragraph
          ) : (
            <React.Fragment>
              It looks like we’re having technical issues…
              <br />
              <br />
              Try refreshing the page!
            </React.Fragment>
          )}
        </Typography>
        <Button
          color="primary"
          variant="contained"
          aria-label="Create an issue report"
          onClick={() => {
            const lastEventId = Raven.lastEventId();
            Raven.showReportDialog({ eventId: lastEventId });
          }}
        >
          Create Issue Report
        </Button>
      </div>
    </div>
  );

  return !props.pageTitle ? (
    errorState
  ) : (
    <PageLayout>
      <PageTitle title={props.pageTitle} />
      {errorState}
    </PageLayout>
  );
});
