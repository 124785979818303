import React from 'react';
import { Tooltip } from '@mui/material';
import { palette } from '@dt/theme';
import { PolicyViolationsStatusesBadgeIconImage } from './PolicyViolationsStatusesBadgeIconImage';

const colors = {
  urgent: {
    backgroundColor: palette.red50,
    color: palette.red30,
  },
  important: {
    backgroundColor: palette.yellow50,
    color: palette.yellow10,
  },
  info: {
    backgroundColor: palette.blue50,
    color: palette.blue,
  },
};

/*
 * Wrapper around `PolicyViolationsStatusesIcon` to show the user a count of a specific type.
 *
 * @param count - How many policy violations of the desired type to render.
 * @param type - What type of policy violation icon to show.
 */
const PolicyViolationsStatusesTagComponent = function PolicyViolationsStatusesTag({ count, type }) {
  const hasSomeCount = count > 0;
  const toVariantType = type === 'urgent' ? 'bad' : type === 'important' ? 'warning' : 'info';
  const toVariantHumanReadable = type === 'info' ? 'proactive' : type;

  return (
    <Tooltip
      title={`${count} unresolved ${toVariantHumanReadable} policy violation${count === 0 || count > 1 ? 's' : ''}`}
      aria-label={`${toVariantHumanReadable} count`}
    >
      {/* Span is used for Tooltip to register content area. */}
      <span
        style={{
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '2px 4px',
          color: hasSomeCount ? colors[type].color : palette.gray35,
          backgroundColor: hasSomeCount ? colors[type].backgroundColor : palette.gray45,
          borderRadius: '2px',
        }}
      >
        <PolicyViolationsStatusesBadgeIconImage
          background={!hasSomeCount ? 'gray' : null}
          color={!hasSomeCount ? palette.gray35 : void 0}
          type={toVariantType}
          tiny
        />
        <span style={{ marginLeft: 4, marginRight: 4 }}>{count}</span>
      </span>
    </Tooltip>
  );
};

export const PolicyViolationsStatusesTag = PolicyViolationsStatusesTagComponent;
