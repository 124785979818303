import { clearSessionAndRedirectToExpired } from '@dt/session';
import { getUserAccount } from '@dt/session';
import { ApolloError } from 'apollo-server-errors';
import { parseISO } from 'date-fns';
import qs from 'query-string';
import db from './database';
import defaultForeignKeyStrategy from './defaultForeignKeyStrategy';
import extractNamedTypeFromField from './extractNamedTypeFromField';

export class ApolloLinkSchemaRestError extends ApolloError {
  statusCode;

  constructor(message, statusCode) {
    super(message, 'REST_NETWORK_RESOLVER_FAILED');

    Object.defineProperty(this, 'statusCode', { value: statusCode });
  }
}

const httpHeadersMap = {
  aIm: 'A-IM',
  accept: 'Accept',
  acceptCharset: 'Accept-Charset',
  acceptEncoding: 'Accept-Encoding',
  acceptLanguage: 'Accept-Language',
  acceptDatetime: 'Accept-Datetime',
  accessControlRequestMethod: 'Access-Control-Request-Method',
  accessControlRequestHeaders: 'Access-Control-Request-Headers',
  authorization: 'Authorization',
  cacheControl: 'Cache-Control',
  connection: 'Connection',
  contentLength: 'Content-Length',
  contentType: 'Content-Type',
  cookie: 'Cookie',
  date: 'Date',
  expect: 'Expect',
  forwarded: 'Forwarded',
  from: 'From',
  host: 'Host',
  ifMatch: 'If-Match',
  ifModifiedSince: 'If-Modified-Since',
  ifNoneMatch: 'If-None-Match',
  ifRange: 'If-Range',
  ifUnmodifiedSince: 'If-Unmodified-Since',
  maxForwards: 'Max-Forwards',
  origin: 'Origin',
  pragma: 'Pragma',
  proxyAuthorization: 'Proxy-Authorization',
  range: 'Range',
  referer: 'Referer',
  te: 'TE',
  userAgent: 'User-Agent',
  upgrade: 'Upgrade',
  via: 'Via',
  warning: 'Warning',
};

function redirectToExpiredSharedLinkPage() {
  window.location.assign(`/management/share/error?reason=expired`);
}

/**
 * HTTP headers are case-insensitive and often have dash in their name
 * which makes them hard to use in JavaScript and Graphql. Therefore we
 * use a normalized version if the header name throughout the code and
 * here de-normalize the back to be used by global `fetch`
 *
 * Example:
 *  in  => { authorization: 'a', contentType: 'b' }
 *  out => { 'Authorization': 'a', 'Content-Type': 'b' }
 */
export function denormalizeHTTPHeaders(normalizedHeaders) {
  const out = {};
  for (const [k, v] of Object.entries(normalizedHeaders)) {
    const key = httpHeadersMap[k] || k;
    out[key] = v;
  }
  return out;
}

/*
 * The following few functions borrowed from Reach Router utils https://github.com/reach/router/blob/master/src/lib/utils.js
 */
const paramRe = /^:(.+)/;

// This is a modified version of @reach/router's insertParams modified for our
// needs. It builds a URL by combining a base path, path, and params. For each
// param, it first checks if the path has a named param with the same name. If
// it does, it replaces it with the param value. Otherwise, it appends the
// param as a querystring arg. So for example:
// `buildURL('http://www.example.com', 'api/users/:id', { id: '1', foo: 'bar'})`
// becomes
// http://www.example.com/api/users/1?foo=bar
export function buildURL(base, path, endpoints = {}, params = {}) {
  let segments = path.split('/');
  // We can't reassign params since 'experimental.const_params'
  // is set in  'flowconfig'
  let unusedParams = params;
  segments = segments.map(s => {
    if (paramRe.test(s)) {
      const key = s.slice(1);
      if (endpoints[key]) return endpoints[key];
      if (unusedParams[key]) {
        // if `:param` has value in params remove it
        // so it won't end up in query string
        const { [key]: value, ...rest } = unusedParams;
        // The spread needed since flow complains about 'Recursion limit exceeded'
        unusedParams = { ...rest };
        return value;
      }
      throw new Error(`Missing a parameter for "${key}"`);
    } else {
      return s;
    }
  });

  const url = base + segments.join('/');
  return qs.stringifyUrl({ url, query: unusedParams }, { skipNull: true });
}

/*
 * End borrowed stuff
 */

function makeFKInvalidArgumentsError(node) {
  return new Error(
    `A path argument with a string value is required on all @restEndpoint directives. Found this invalid directive: ${
      JSON.stringify(node) || 'JSON error'
    }`,
  );
}

function makeEndpointInvalidArgumentsError(node) {
  return new Error(
    `A path argument with a string value is required on all @restEndpoint directives. Found this invalid directive: ${
      JSON.stringify(node) || 'JSON error'
    }`,
  );
}

export const makeDefaultResolver = field => obj => obj[field.name.value];

export const makeResolverForRestFK = (field, directiveNode, { customForeignKeyStrategies }) => {
  const { arguments: args } = directiveNode;
  if (!args) {
    throw makeFKInvalidArgumentsError(directiveNode);
  }

  const fromFieldNode = args.find(node => node.name.value === 'fromField');
  if (!fromFieldNode || fromFieldNode.value.kind !== 'StringValue') {
    throw makeFKInvalidArgumentsError(directiveNode);
  }
  const fromField = fromFieldNode.value.value;

  const customFindStrategyNode = args.find(node => node.name.value === 'custom_find_strategy');
  let findStrategy = defaultForeignKeyStrategy;
  let findStrategyName = '';
  if (customFindStrategyNode) {
    if (customFindStrategyNode.value.kind !== 'StringValue') {
      throw makeFKInvalidArgumentsError(directiveNode);
    }
    findStrategyName = customFindStrategyNode.value.value;

    if (!customForeignKeyStrategies) {
      throw new Error(
        `An invalid custom_find_strategy was provided. Strategy '${findStrategyName}' not found in customForeignKeyStrategies.`,
      );
    }
    findStrategy = customForeignKeyStrategies[findStrategyName];
  }

  if (!findStrategy) {
    throw new Error(
      `An invalid custom_find_strategy was provided. Strategy '${findStrategyName}' not found in customForeignKeyStrategies.`,
    );
  }

  const type = extractNamedTypeFromField(field);
  if (typeof type !== 'string') {
    throw new Error(`Invalid node: ${type.error}`);
  }

  return async (obj, args, context) => {
    if (!findStrategy) {
      throw new Error(`An invalid custom_find_strategy was provided.`);
    }

    return findStrategy(fromField, type, obj, args, context);
  };
};

export const makeResolverForRestEndpoint = (field, directiveNode, linkOptions, responseTypeNode) => {
  if (!directiveNode.arguments) {
    throw makeEndpointInvalidArgumentsError(directiveNode);
  }

  const args = directiveNode.arguments;
  const pathNode = args.find(node => node.name.value === 'path');
  if (!pathNode || pathNode.value.kind !== 'StringValue') {
    throw makeEndpointInvalidArgumentsError(directiveNode);
  }
  const path = pathNode.value.value;

  // User can optionally provide "headers" at the query level.
  const queryHeaders = {};
  const queryHeadersTypeNode = args.find(node => node.name.value === 'headers');
  const queryHeaderNodes =
    queryHeadersTypeNode && queryHeadersTypeNode.value.kind === 'ObjectValue' ? queryHeadersTypeNode.value.fields : [];
  for (const headerNode of queryHeaderNodes) {
    if (
      headerNode.kind === 'ObjectField' &&
      headerNode.name.kind === 'Name' &&
      headerNode.value &&
      headerNode.value.kind === 'StringValue' &&
      typeof headerNode.name.value === 'string'
    ) {
      queryHeaders[headerNode.name.value] = headerNode.value.value;
    }
  }

  // User can optionally provide "method" at the query level.
  const methodNode = args.find(n => n.name.value === 'method');
  const method = methodNode && methodNode.value.kind === 'StringValue' ? methodNode.value.value : 'GET';

  const baseUri = linkOptions.uri || ''; // This can be blank if the @restEndpoint provides the full URI

  const fieldNameToTypeNameMap = (responseTypeNode.fields || []).reduce(
    (index, field) => {
      index[field.name.value] = extractNamedTypeFromField(field);
      return index;
    },
    { ...null },
  );

  return async function resolve(
    obj,
    args,
    context,
    /* info: Info, */ // Commented out because of linter
  ) {
    // Construct fetch options.
    const rest = context.rest;
    let url, fetchOptions;

    // Extract `headers` and `body` from args since they
    // don't contribute to `path` or query string variables
    let { headers, body, ...otherVariables } = args;

    // Headers can be set from
    //  - link options
    //  - schema
    //  - query variables
    headers = { ...linkOptions.headers, ...queryHeaders, ...headers };

    // Retyping the `headers` to make flow happy
    headers;

    // Default `contentType` to `application/json` if not present
    if (!Object.hasOwnProperty.call(headers, 'contentType')) {
      headers.contentType = 'application/json';
    }

    // De-normalize the header names before we pass it into `fetch`
    headers = denormalizeHTTPHeaders(headers);
    if (method === 'GET') {
      url = buildURL(baseUri, path, linkOptions.endpoints, otherVariables);
      fetchOptions = { headers: headers };
    } else {
      url = buildURL(baseUri, path, linkOptions.endpoints, otherVariables);
      fetchOptions = {
        headers: headers,
        method,
        ...(body && {
          body: headers['Content-Type'] === 'application/json' ? JSON.stringify(body) : body,
        }),
      };
    }

    // Call site for fetching the REST endpoint.
    const response = await linkOptions.fetch(url, fetchOptions);
    const parsedBody = await linkOptions.fetchParse(response);

    // Error handling.
    // See also https://www.apollographql.com/docs/react/data/error-handling/
    if (parsedBody._type === 'error') {
      if (parsedBody.status === 401) {
        try {
          const result = await getUserAccount();
          if (result?.authorization === 'ScopedAccessToken') {
            return await redirectToExpiredSharedLinkPage();
          } else {
            return await clearSessionAndRedirectToExpired();
          }
        } catch (error) {
          return await clearSessionAndRedirectToExpired();
        }
      } else {
        const message = parsedBody.description ? parsedBody.description : parsedBody.title;

        // Rest resolver "network errors" are marked  with a status code.
        throw new ApolloLinkSchemaRestError(message, parsedBody.status);
      }
    } else {
      const normalizer = linkOptions.restNormalizer || (b => b);
      const normalizedBody = normalizer(parsedBody.body);
      // If the normalized body is not an object, we can't index it.
      if (normalizedBody && typeof normalizedBody === 'object') {
        rest.db = db(context.rest.db || {}, normalizedBody, fieldNameToTypeNameMap);
      }
      return normalizedBody;
    }
  };
};

/*
 * Allows a scalar type for Date data in ISO8601 format.
 *
 */
export const makeResolverForDate = field => obj => {
  const value = obj[field.name.value];
  if (typeof value === 'string') {
    return parseISO(value);
  } else if (value === null || typeof value === 'undefined') {
    return value;
  } else {
    throw new Error(`Invalid type found for Date`);
  }
};

/*
 * Allows a scalar type for JSON data.
 *
 * NOTE: The type is called "JSON" and not "Json" because the flow type generation
 *       knows what "JSON" is.
 */
export const makeResolverForJson = field => obj => {
  const value = obj[field.name.value];
  if (typeof value === 'object') {
    return value;
  } else if (typeof value === 'string') {
    return JSON.parse(value);
  } else {
    throw new Error(`Invalid type found for JSON`);
  }
};
