import React from 'react';
import { Card, Grid, Typography } from '@mui/material';
import { TagApiOperationsCount, TagDiscoveredVia, TagHostedOn } from '@dt/material-components';
import { palette } from '@dt/theme';
import { PolicyViolationsStatusesTagStrip } from '../policy_violations';
import { AssetsTypeIcon } from './AssetsTypeIcon';

const styles = {
  assetCard: {
    padding: 16,
  },
  avatar: { background: palette.brand35 },
  violationTagStrip: {
    display: 'flex',
  },
  urlWrap: {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
  },
};

const AssetsCardComponent = function AssetsCard({
  name,
  url,
  discovered_via,
  hosted_on,
  policy_violations,
  api_operations_count,
  date_created,
  asset_type_icon_url,
  asset_type_name,
  children,
}) {
  return (
    <Card style={styles.assetCard}>
      <Grid container alignItems="flex-start" wrap="nowrap" spacing={2}>
        <Grid item>
          <AssetsTypeIcon asset_type_icon_url={asset_type_icon_url} asset_type_name={asset_type_name} iconSize={36} />
        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          direction="column"
          spacing={1}
          style={{ maxWidth: 'calc(100% - 48px)' }} // Need this to make sure that content does not overflow the card
        >
          <Grid item container justifyContent="space-between" wrap="nowrap">
            <Grid item>
              <Typography variant="body1" component="div" style={styles.urlWrap}>
                {name}
              </Typography>
              {url && (
                <Typography variant="caption" component="div" style={styles.urlWrap}>
                  {url}
                </Typography>
              )}
            </Grid>
            {policy_violations && (
              <Grid item>
                <PolicyViolationsStatusesTagStrip
                  policy_violations_data={policy_violations.map(({ status, violated_policy_rule }) => ({
                    status,
                    relevance: violated_policy_rule.relevance,
                  }))}
                />
              </Grid>
            )}
          </Grid>
          <Grid item container justifyContent="space-between" wrap="nowrap">
            <Grid item>
              <div style={{ display: 'flex' }}>
                <TagDiscoveredVia discovered_via={discovered_via} discovered_date={date_created} />
                <TagHostedOn hosted_on={hosted_on} />
              </div>
            </Grid>
            <Grid item style={{ alignSelf: 'flex-end' }}>
              <TagApiOperationsCount count={api_operations_count || 0} />
            </Grid>
          </Grid>

          {children && (
            <Grid item container justifyContent="space-between" wrap="nowrap">
              <Grid item style={{ width: '100%' }}>
                {children}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export const AssetsCard = React.memo(AssetsCardComponent);
