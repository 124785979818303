import { createAction } from 'redux-actions';

export const cloudResourcesReceived = createAction('cloud_resources/received', cloud_resources => ({
  cloud_resources,
}));

export const cloudResourcesDetailsMounted = createAction('mounted/cloud_resource_details', cloudResourceId => ({
  cloudResourceId,
  key: 'cloud_resource_details',
}));

export const cloudResourcesDetailsAuthenticatorsMounted = createAction(
  'mounted/cloud_resource_details/authenticators',
  () => ({
    key: 'cloud_resource_details_authenticators',
  }),
);

export const reachedEndOfCloudResourcesList = createAction('cloud_resources/list/reached_end', () => undefined);

export const reachedEndOfCloudResourcesListForAssetSearchId = createAction(
  'cloud_resources/list/search/reached_end',
  search_id => search_id,
);

export const cloudResourcesReceivedIdsForAssetSearch = createAction(
  'cloud_resources/search/ids/received',
  (searchId, ids) => ({
    searchId,
    ids,
  }),
);
