import immer from 'immer';
import { assetGroupsMembershipReceived } from './actions';

export const initialState = {
  ids: {},
  for_asset_group_id: {},
  for_asset_id: {},
};

export default immer((draft, action) => {
  switch (action.type) {
    case assetGroupsMembershipReceived.toString(): {
      action.payload.forEach(asset_group_membership => {
        draft.ids[asset_group_membership.id] = asset_group_membership;

        const list = draft.for_asset_group_id[asset_group_membership.asset_group_id] || [];

        draft.for_asset_group_id[asset_group_membership.asset_group_id] = list.filter(
          id => id !== asset_group_membership.id,
        );

        draft.for_asset_group_id[asset_group_membership.asset_group_id].push(asset_group_membership.id);

        const asset_id =
          asset_group_membership.web_application_id ||
          asset_group_membership.cloud_resource_id ||
          asset_group_membership.network_service_id ||
          asset_group_membership.restful_api_id;

        if (asset_id) {
          const assetList = draft.for_asset_id[asset_id] || [];
          draft.for_asset_id[asset_id] = assetList.filter(id => id !== asset_group_membership.id);
          draft.for_asset_id[asset_id].push(asset_group_membership);
        }
      });
      return;
    }
  }
}, initialState);
