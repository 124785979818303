import React from 'react';
import { Tooltip, Typography } from '@mui/material';
import { palette } from '@dt/theme';
import {
  getDisplayTextFromComplianceStandard,
  getDisplayTextFromComplianceStandardCriteria,
  getIconFromComplianceStandard,
} from './util';

const MultiComplianceTag = ({ complianceStandard, complianceReferences }) => {
  const tooltipText = (
    <>
      <strong>
        {getDisplayTextFromComplianceStandard(complianceStandard)} - {complianceReferences.length} Reference
        {complianceReferences.length > 1 ? 's' : ''}
      </strong>
      <br />
      {complianceReferences.map(r => (
        <div key={r.compliance_standard_criteria}>
          <br />
          {getDisplayTextFromComplianceStandardCriteria(r.compliance_standard_criteria)}
          <hr />
          {r.description}
        </div>
      ))}
    </>
  );

  return (
    <Tooltip title={tooltipText}>
      <div
        style={{
          padding: '4px 6px',
          backgroundColor: palette.gray45,
          borderRadius: 4,
          margin: '8px 4px 0',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img
          src={getIconFromComplianceStandard(complianceStandard)}
          style={{ height: '14px', width: '14px', marginRight: 4 }}
        />
        <Typography variant="caption">{getDisplayTextFromComplianceStandard(complianceStandard)}</Typography>
      </div>
    </Tooltip>
  );
};

export default React.memo(MultiComplianceTag);
