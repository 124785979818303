import React, { memo, useEffect, useMemo, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import SearchIcon from '@mui/icons-material/Search';
import { Checkbox, Grid, List, ListItem, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';
import { useLazyQuery } from '@dt/apollo-link-schema-rest';
import { ErrorState } from '@dt/components';
import asset_tags from '@dt/graphql-support/horizon/asset_tags';
import { Text } from '@dt/material-components';
import debounce from 'lodash/fp/debounce';

const useStyles = makeStyles(theme => ({
  mainList: {
    height: 300,
    overflow: 'scroll',
    overflowX: 'auto',
  },
  list: {
    padding: 0,
    paddingLeft: theme.spacing(3),
  },
  itemLogo: {
    justifyContent: 'center',
  },
}));

const InventoryTableFilterAssetTagsComponent = function InventoryTableFilterAssetTags({
  selectedTagUuId,
  setSelectedTagUuId,
  selectedAssetTags,
  onToggleAssetTagKey,
}) {
  const classes = useStyles();

  const [fetchAssetTagKeysQuery, { data, error, loading, called, fetchMore, pageNumber }] = useLazyQuery(
    asset_tags.list,
    {
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    if (!called) {
      fetchAssetTagKeysQuery({});
    }
  }, [fetchAssetTagKeysQuery, called]);

  const [filter, setFilter] = useState('');
  const handleChange = useMemo(
    () =>
      debounce(500)(filter =>
        fetchAssetTagKeysQuery(
          filter
            ? {
                variables: {
                  filter_by_text: filter,
                },
              }
            : {},
        ),
      ),
    [fetchAssetTagKeysQuery],
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          value={filter}
          onChange={e => {
            setFilter(e.target.value);
            handleChange(e.target.value);
          }}
          id="asset-keys-filter"
          label={'Filter'}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <List className={classes.mainList} component="nav">
          {error ? (
            <ErrorState error={error} />
          ) : !pageNumber && loading ? (
            [0, 1, 2, 3, 4].map((_, key) => (
              <ListItem button dense key={key}>
                <Skeleton animation="wave" width={`100%`} height={40} />
              </ListItem>
            ))
          ) : (
            data?.asset_tag_key_list.asset_tag_keys.map(
              (assetTag, key) =>
                assetTag && (
                  <ListItem
                    button
                    dense
                    key={key}
                    selected={selectedTagUuId == assetTag?.id}
                    onClick={() => setSelectedTagUuId(assetTag?.id)}
                  >
                    <Checkbox
                      checked={
                        Boolean(selectedAssetTags[assetTag.name] === null) ||
                        Boolean(selectedAssetTags[assetTag.name]?.length)
                      }
                      indeterminate={Boolean(selectedAssetTags[assetTag.name]?.length)}
                      onClick={() => onToggleAssetTagKey(assetTag.name)}
                    />
                    <Text noWrap>{assetTag.name}</Text>
                  </ListItem>
                ),
            )
          )}
          {fetchMore && (
            <Waypoint
              onEnter={() => {
                if (fetchMore) {
                  fetchMore();
                }
              }}
            >
              <Box component={'div'}>
                <ListItem button dense>
                  <Skeleton animation="wave" width={`100%`} height={40} />
                </ListItem>
                <ListItem button dense>
                  <Skeleton animation="wave" width={`100%`} height={40} />
                </ListItem>
                <ListItem button dense>
                  <Skeleton animation="wave" width={`100%`} height={40} />
                </ListItem>
              </Box>
            </Waypoint>
          )}
        </List>
      </Grid>
    </Grid>
  );
};

export default memo(InventoryTableFilterAssetTagsComponent);
