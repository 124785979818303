import React from 'react';
import { TextField } from '@mui/material';

const wStyle = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'baseline',
};

const defaultHintTex = 'Enter a keyword to narrow results (default: all findings)';

const defaultKeywordText = 'Keyword:';

const SearchBox = ({ value, hintText, onChange, keywordText }) => {
  const handleChange = e => onChange(e.target.value);
  return (
    <div style={wStyle}>
      <div style={{ display: 'inline-block', marginRight: '10px' }}>{keywordText || defaultKeywordText}</div>
      <TextField placeholder={hintText || defaultHintTex} onChange={handleChange} defaultValue={value} fullWidth />
    </div>
  );
};

export default SearchBox;
