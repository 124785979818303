import React, { memo } from 'react';
import { palette } from '@dt/theme';

function ProductIconSupplyChainSecurity({ foregroundColor = palette.brand30, backgroundColor = palette.white }) {
  return (
    <svg
      viewBox="0 0 160 160"
      width="100%"
      // This is important for IE 11
      style={{ maxHeight: 'inherit ' }}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M160 127.19C160.001 131.499 159.154 135.766 157.505 139.747C155.857 143.729 153.44 147.346 150.393 150.393C147.346 153.44 143.729 155.857 139.747 157.505C135.766 159.154 131.499 160.001 127.19 160H32.81C24.1082 160 15.7629 156.543 9.60983 150.39C3.45676 144.237 0 135.892 0 127.19L0 32.8C0.00265164 24.1 3.46058 15.7572 9.61336 9.60629C15.7661 3.45539 24.11 1.12044e-06 32.81 1.52453e-06H127.19C131.498 -0.00131195 135.764 0.846116 139.745 2.49388C143.726 4.14165 147.343 6.55747 150.39 9.60336C153.436 12.6493 155.853 16.2655 157.502 20.2457C159.151 24.2258 160 28.4918 160 32.8V127.19Z"
        fill={backgroundColor}
      />
      <path
        d="M82.1566 44.1832C91.1106 35.2293 97.826 29.1771 102.303 26.0266C106.835 22.9314 110.953 21.7984 114.656 22.6274C118.359 23.4565 122.864 26.524 128.17 31.8301C133.531 37.1914 136.626 41.7236 137.455 45.4268C138.285 49.13 137.124 53.22 133.973 57.697C130.878 62.1187 124.826 68.8342 115.817 77.8434C106.808 86.8526 100.065 92.9324 95.5876 96.0829C91.1658 99.1781 87.1034 100.311 83.4002 99.482C79.6971 98.653 75.1648 95.5578 69.8035 90.1965C65.3655 85.7585 62.4935 81.8811 61.1875 78.5644L67.4566 72.9519C67.1005 74.2466 67.0537 75.4073 67.3163 76.434C67.9796 78.7553 70.2733 81.8782 74.1976 85.8024C78.1218 89.7267 81.2447 92.0204 83.5661 92.6837C85.9427 93.2917 89.0103 92.2139 92.7687 89.4503C96.5824 86.742 102.635 81.2425 110.925 72.9519C119.216 64.6612 124.715 58.609 127.424 54.7953C130.187 51.0368 131.237 47.9969 130.574 45.6755C129.966 43.2989 127.7 40.1484 123.776 36.2241C119.852 32.2999 116.701 30.0338 114.324 29.4258C112.003 28.7625 108.936 29.785 105.122 32.4933C101.363 35.2569 95.3388 40.784 87.0481 49.0747C83.8332 52.2896 81.0337 55.1636 78.6499 57.697C75.8911 57.2768 69.8865 57.1434 67.4754 59.8659C67.422 59.9297 67.369 59.9932 67.3163 60.0565C67.3674 59.9914 67.4205 59.9279 67.4754 59.8659C70.9071 55.766 75.8011 50.5387 82.1566 44.1832Z"
        fill={foregroundColor}
      />
      <path
        d="M77.8434 115.817C68.8894 124.771 62.174 130.823 57.697 133.974C53.1648 137.069 49.0471 138.202 45.3439 137.373C41.6407 136.544 37.1361 133.476 31.8301 128.17C26.4688 122.809 23.3736 118.276 22.5445 114.573C21.7154 110.87 22.8761 106.78 26.0266 102.303C29.1218 97.8814 35.174 91.166 44.1832 82.1567C53.1924 73.1475 59.9355 67.0677 64.4124 63.9173C68.8342 60.8221 72.8966 59.689 76.5998 60.5181C80.3029 61.3471 84.8352 64.4423 90.1965 69.8036C94.6345 74.2417 97.5065 78.119 98.8125 81.4357L92.5434 87.0483C92.8995 85.7535 92.9463 84.5928 92.6837 83.5662C92.0204 81.2448 89.7267 78.122 85.8024 74.1977C81.8782 70.2734 78.7553 67.9797 76.4339 67.3164C74.0573 66.7084 70.9897 67.7862 67.2313 70.5498C63.4176 73.2581 57.3654 78.7576 49.0747 87.0483C40.784 95.3389 35.2845 101.391 32.5762 105.205C29.8127 108.963 28.7625 112.003 29.4258 114.325C30.0338 116.701 32.2999 119.852 36.2241 123.776C40.1484 127.7 43.2988 129.966 45.6755 130.574C47.9969 131.238 51.0645 130.215 54.8782 127.507C58.6366 124.743 64.6612 119.216 72.9519 110.925C76.1668 107.711 78.9663 104.837 81.3501 102.303C84.1089 102.723 90.1135 102.857 92.5246 100.134C92.578 100.07 92.631 100.007 92.6837 99.9437C92.6326 100.009 92.5795 100.072 92.5246 100.134C89.0929 104.234 84.1989 109.461 77.8434 115.817Z"
        fill={foregroundColor}
      />
    </svg>
  );
}

export default memo(ProductIconSupplyChainSecurity);
