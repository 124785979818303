import React, { memo } from 'react';
import { Grid } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { PolicyViolationsStatusesTag } from '../../policy_violations';

const InventoryTableCellPolicyViolationsComponent = function InventoryTableCellPolicyViolations({ asset }) {
  return (
    <TableCell>
      <Grid container spacing={1} justifyContent={'center'}>
        <Grid item xs={4}>
          <PolicyViolationsStatusesTag count={asset.open_urgent_policy_violations_count} type="urgent" />
        </Grid>
        <Grid item xs={4}>
          <PolicyViolationsStatusesTag count={asset.open_important_policy_violations_count} type="important" />
        </Grid>
        <Grid item xs={4}>
          <PolicyViolationsStatusesTag count={asset.open_proactive_policy_violations_count} type="info" />
        </Grid>
      </Grid>
    </TableCell>
  );
};

export default memo(InventoryTableCellPolicyViolationsComponent);
