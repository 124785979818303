import React, { memo } from 'react';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { Link } from '@reach/router';
import OpenPolicyViolationsCountCardPolicyViolationList from './OpenPolicyViolationsCountCardPolicyViolationList';
import OpenPolicyViolationsCountCardTagStrips from './OpenPolicyViolationsCountCardTagStrips';

export default memo(function OpenPolicyViolationsCountCard(props) {
  if (props.isLoading) {
    return (
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Skeleton variant="rectangular" animation="wave" width={'30%'} height={30} />
            </Grid>
            <Grid item xs={12} container spacing={1}>
              {[1, 2, 3, 4, 5, 6].map((_, i) => (
                <Grid item xs={12} key={i}>
                  <Skeleton variant="rectangular" animation="wave" width={'60%'} height={30} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  const { productBasePath, id, policy_violations_count } = props;

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Typography variant="body2" color="textPrimary" style={{ fontWeight: 'bold' }}>
                  Open Policy Violations Count
                </Typography>
              </Grid>
              <Grid item>
                <OpenPolicyViolationsCountCardTagStrips policy_violations_count={policy_violations_count} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <OpenPolicyViolationsCountCardPolicyViolationList productBasePath={productBasePath} id={id} />
          </Grid>
          <Grid item xs={12}>
            <Link to={`${productBasePath}/cloud-resources/${id}/policy-violations/`}>
              <Button variant="text" color="primary">
                See All Violations
              </Button>
            </Link>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
});
