const DefaultResolvers = {
  PaginationInformation: () => ({
    next_cursor: () => undefined,
  }),
  Date: () => new Date(),
};
const getMockResolvers = mocks =>
  process.env.NODE_ENV === 'test'
    ? {
        ...DefaultResolvers,
        ...mocks,
      }
    : null;

export default getMockResolvers;
