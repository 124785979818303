import { useRef } from 'react';
import defaultNextCursorSelector from './defaultNextCursorSelector';
import makeFetchMore from './makeFetchMore';

export default function useBaseQuery(queryResult, options) {
  const pageNumber = useRef(0);

  const next_cursor_selector =
    options && typeof options.cursorSelector === 'function' ? options.cursorSelector : defaultNextCursorSelector;
  const next_cursor = queryResult.data ? next_cursor_selector(queryResult.data) : null;

  const prevCursor = useRef(null);
  const cursorChanged = prevCursor.current !== next_cursor;
  prevCursor.current = next_cursor;

  const fetchMore = useRef(null);

  if (cursorChanged && next_cursor) {
    ++pageNumber.current;
    fetchMore.current = makeFetchMore(queryResult.data, queryResult.fetchMore, next_cursor);
  } else if (!next_cursor) {
    fetchMore.current = null;
  }

  return {
    ...queryResult,
    pageNumber: pageNumber.current,
    fetchMore: fetchMore.current,
  };
}
