import { createAction } from 'redux-actions';

const requestNotifyUser = createAction('REQUEST_NOTIFY_USER', notification => ({ notification }));

const dismissNotificationClicked = createAction('DISMISS_NOTIFICATION_CLICKED', notification => ({
  notification,
}));

export default {
  requestNotifyUser: requestNotifyUser,
  dismissNotificationClicked: dismissNotificationClicked,
};
