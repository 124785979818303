import { values } from '@dt/functions';
import { SpecialScanRequestTypeEnum, special_scan_requests } from '@dt/horizon-api';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import { select } from '@dt/redux-saga-wrapped-effects';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getAllPolicies } from '../policies/sagas';
import resourceType from '../policy_violations/resourceType';
import { getPolicyViolationDetails, getPolicyViolationList } from '../policy_violations/resource_fetch.sagas';
import { handleNormalizedResponse, paginateToEnd, takePatternAndFetch } from '../resource_fetch/sagas';
import { getSpecialScanRequestTypeList } from '../special_scans/resource_fetch.sagas';
import { getLastDetectInjectScan } from '../special_scans/selectors';
import { getAllRestfulAPIsLazily } from './../restful_apis/sagas';
import {
  detectInjectExcludedOperectionIdsReceived,
  detectInjectStartScanClicked,
  detectInjectStatusRecived,
} from './actions';
import { excludedOperationList, getSqlInjectedPolicyViolations } from './selectors';

const SQL_INJECTION_RULE_TYPE_IDS = ['92eb7e9e-9daa-5922-8167-6a219e0f7287'];

export default function* watchForResourceFetching() {
  yield all([
    call(takePatternAndFetch, 'detect_inject/mounted*', function* () {
      yield all([
        call(getAllPolicies, {}),
        call(getSpecialScanRequestTypeList, {
          type: SpecialScanRequestTypeEnum.DETECT_AND_INJECT,
        }),
      ]);

      const lastScanResults = yield* select(getLastDetectInjectScan, {});
      if (lastScanResults !== 'no-scan') {
        const { date_created, date_completed, current_status, scan_configuration } = lastScanResults;

        const { excluded_api_operation_ids } = scan_configuration;
        if (excluded_api_operation_ids) {
          yield put(detectInjectExcludedOperectionIdsReceived(excluded_api_operation_ids));
        }

        yield put(detectInjectStatusRecived(current_status, date_created, date_completed));
      }

      yield call(getAllDetectInjectPolicyViolations);

      const policyViolations = yield* select(getSqlInjectedPolicyViolations, {});

      yield all(values(policyViolations).map(policy => call(getPolicyViolationDetails, policy.id)));

      yield call(getAllRestfulAPIsLazily, {});
    }),
    takeEvery(detectInjectStartScanClicked.toString(), detectInjectScanStartSaga),
  ]);
}

function* getAllDetectInjectPolicyViolations() {
  yield call(paginateToEnd, getPolicyViolationList, resourceType, {
    filter_by_policy_rule_type_id: SQL_INJECTION_RULE_TYPE_IDS,
  });
}

function* detectInjectScanStartSaga() {
  const excluded_api_operation_ids = yield* select(excludedOperationList, {});

  yield put(detectInjectExcludedOperectionIdsReceived(excluded_api_operation_ids));
  const response = yield* callPromise(special_scan_requests.create, {
    scan_type: SpecialScanRequestTypeEnum.DETECT_AND_INJECT,
    scan_configuration: {
      excluded_api_operation_ids,
    },
  });

  yield call(handleNormalizedResponse, response);
}
