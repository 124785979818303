import React, { memo, useState } from 'react';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import { AssetTagsEditDialog } from '@dt/components';
import { Tag } from '@dt/material-components';

const InventoryTableCellAssetTag = ({ assetId, assetName, assetTags }) => {
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  return (
    <TableCell align="left">
      <Box display={'flex'} flexWrap={'wrap'}>
        {assetTags?.map(
          (assetTag, idx) =>
            assetTag && (
              <Box style={{ cursor: 'pointer' }} onClick={() => setEditDialogOpen(true)} key={idx}>
                <Tag
                  label={`${assetTag.tag}${assetTag.value ? ':' + assetTag.value : ''}`}
                  maxWidth={100}
                  key={assetTag.id}
                />
              </Box>
            ),
        )}
      </Box>
      {isEditDialogOpen && (
        <AssetTagsEditDialog
          isOpen={isEditDialogOpen}
          onClose={() => setEditDialogOpen(false)}
          assetName={assetName}
          assetId={assetId}
        />
      )}
    </TableCell>
  );
};

export default memo(InventoryTableCellAssetTag);
