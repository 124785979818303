import { createAction } from 'redux-actions';

export const networkServicesReceived = createAction('network_services/received', network_services => ({
  network_services,
}));

export const reachedEndOfNetworkServicesList = createAction('network_services/list/reached_end', () => undefined);

export const reachedEndOfNetworkServicesListForAssetSearchId = createAction(
  'network_services/list/search/reached_end',
  search_id => search_id,
);

export const networkServicesReceivedIdsForAssetSearch = createAction(
  'network_services/search/ids/received',
  (searchId, ids) => ({
    searchId,
    ids,
  }),
);
