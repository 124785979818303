import immer from 'immer';
import {
  policiesCreate,
  policiesCreateDone,
  policiesCreateError,
  policiesCreateSuccess,
  policiesDeleteButtonClicked,
  policiesReceived,
} from './actions';

const initialState = {
  ids: {},
  create: {
    isLoading: false,
    newlyCreatedPolicyId: null,
    isError: false,
  },
};

export default immer((draft, action) => {
  switch (action.type) {
    case policiesReceived.toString(): {
      action.payload.forEach(policy => {
        draft.ids[policy.id] = policy;
      });
      return;
    }

    case policiesCreate.toString(): {
      draft.create.isLoading = true;
      draft.create.newlyCreatedPolicyId = null;
      draft.create.isError = false;
      return;
    }

    case policiesCreateSuccess.toString(): {
      draft.create.isLoading = false;
      draft.create.newlyCreatedPolicyId = action.payload;
      draft.create.isError = false;
      return;
    }

    case policiesCreateError.toString(): {
      draft.create.isLoading = false;
      draft.create.newlyCreatedPolicyId = null;
      draft.create.isError = true;
      return;
    }
    case policiesCreateDone.toString(): {
      draft.create.newlyCreatedPolicyId = null;
      return;
    }
    case policiesDeleteButtonClicked.toString(): {
      draft.ids[action.payload] = void 0;
      return;
    }
  }
}, initialState);
