import React, { memo, useEffect, useMemo, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import SearchIcon from '@mui/icons-material/Search';
import { Checkbox, Grid, List, ListItem, ListItemIcon, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';
import { useLazyQuery } from '@dt/apollo-link-schema-rest';
import { ErrorState } from '@dt/components';
import supply_chain_secure_apps from '@dt/graphql-support/horizon/supply_chain_secure_apps';
import { Text } from '@dt/material-components';
import debounce from 'lodash/fp/debounce';

const useStyles = makeStyles(theme => ({
  mainList: {
    height: 300,
    overflow: 'scroll',
    overflowX: 'auto',
  },
  list: {
    padding: 0,
    paddingLeft: theme.spacing(3),
  },
  itemLogo: {
    justifyContent: 'center',
  },
}));

const TableFilterMyApps = ({ appType, selectedList, onChange }) => {
  const classes = useStyles();

  const [fetchVendorsQuery, { data, error, loading, called, fetchMore, pageNumber }] = useLazyQuery(
    supply_chain_secure_apps.list,
    {
      variables: {
        filter_by_asset_type: [appType],
      },
    },
  );

  useEffect(() => {
    if (!called) {
      fetchVendorsQuery({});
    }
  }, [fetchVendorsQuery, called]);

  const [filter, setFilter] = useState('');
  const handleTextFilterChange = useMemo(
    () =>
      debounce(500)(filter =>
        fetchVendorsQuery(
          filter
            ? {
                variables: {
                  filter_by_asset_type: [appType],
                  filter_by_text: filter,
                },
              }
            : {
                variables: {
                  filter_by_asset_type: [appType],
                },
              },
        ),
      ),
    [fetchVendorsQuery, appType],
  );

  const handleChange = id => {
    if (selectedList.includes(id)) {
      onChange(selectedList.filter(item => item !== id));
    } else {
      onChange(selectedList.concat(id));
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          value={filter}
          onChange={e => {
            setFilter(e.target.value);
            handleTextFilterChange(e.target.value);
          }}
          id="asset-vendors-filter"
          label={'Filter'}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <List className={classes.mainList} component="nav">
          {error ? (
            <ErrorState error={error} />
          ) : !pageNumber && loading ? (
            [0, 1, 2, 3, 4].map((_, key) => (
              <ListItem button dense key={key}>
                <Skeleton animation="wave" width={`100%`} height={40} />
              </ListItem>
            ))
          ) : (
            data?.supply_chain_secure_app_list.supply_chain_secure_apps.map(
              (app, key) =>
                app && (
                  <ListItem
                    button
                    dense
                    key={key}
                    selected={selectedList.includes(app?.id)}
                    onClick={() => handleChange(app?.id)}
                  >
                    <Checkbox checked={selectedList.includes(app?.id)} />
                    <ListItemIcon className={classes.itemLogo}>
                      <img height={24} width={24} src={app.icon_url} alt={app.name} />
                    </ListItemIcon>
                    <Text component={'div'} variant={'body'} noWrap>
                      {app.name}
                    </Text>
                  </ListItem>
                ),
            )
          )}
          {fetchMore && (
            <Waypoint
              onEnter={() => {
                if (fetchMore) {
                  fetchMore();
                }
              }}
            >
              <Box component={'div'}>
                <ListItem button dense>
                  <Skeleton animation="wave" width={`100%`} height={40} />
                </ListItem>
                <ListItem button dense>
                  <Skeleton animation="wave" width={`100%`} height={40} />
                </ListItem>
                <ListItem button dense>
                  <Skeleton animation="wave" width={`100%`} height={40} />
                </ListItem>
              </Box>
            </Waypoint>
          )}
        </List>
      </Grid>
    </Grid>
  );
};

export default memo(TableFilterMyApps);
