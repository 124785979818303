import config from '@dt/config';
import TaskStatus from '@dt/enums/TaskStatusEnum';
import TemplateScanType from '@dt/enums/TemplateScanTypeEnum';
import fetch, { parse } from '@dt/fetch';
import qs from 'query-string';
import { array, boolean, mixed, object, string } from 'yup';
import { TargetStatusSchema } from './_targets';
import { result } from './util';

const IntegrationsSchema = object().shape({
  ci_cd: boolean(),
  issue_tracker: boolean(),
  trust_kit: boolean(),
});

export const ApplicationSchema = object().shape({
  id: string().required(),
  platform: string().required(),
  name: string().required(),
  subscription: mixed().label('Subscription').oneOf(Object.keys(TemplateScanType)).required(),
  integrations: IntegrationsSchema,
  max_app_protection_score: string(),
});

async function validate(result) {
  return ApplicationSchema.validate(result);
}

async function validateList(result) {
  return object()
    .shape({
      mobile_apps: array().of(ApplicationSchema),
    })
    .validate(result);
}

export async function get(id) {
  if (!id || !id.length) {
    throw new Error('Invalid application ID provided');
  }

  return fetch(`${config.sevenhellApiBaseUrl}/v2/mobile_apps/${id}`).then(parse).then(result).then(validate);
}

export async function list(params) {
  return fetch(
    `${config.sevenhellApiBaseUrl}/v2/mobile_apps${params && qs.stringify(params) ? `?${qs.stringify(params)}` : ''}`,
  )
    .then(parse)
    .then(result)
    .then(validateList);
}

const AppProtectionTaskTargetSchema = object().shape({
  id: string().required(),
  statuses: array().of(TargetStatusSchema).required(),
});

export const AppProtectionTaskSchema = object().shape({
  app_protection_score: string().required(),
  description: string().required(),
  description_intro: string(),
  mobile_app_id: string().required(),
  recommendation: string(),
  secure_code: string(),
  security_finding_id: string(),
  targets: array().of(AppProtectionTaskTargetSchema),
  task_status: mixed().label('taskStatus').oneOf(Object.values(TaskStatus)).required(),
  title: string().required(),
});

export const AppProtectionTasksMetadataSchema = object().shape({
  mobile_app_id: string().required(),
  completed_points: string().required(),
  completed_tasks_count: string().required(),
  not_completed_points: string().required(),
  not_completed_tasks_count: string().required(),
  unknown_points: string().required(),
  unknown_tasks_count: string().required(),
  requires_enterprise_scan_points: string().required(),
  requires_enterprise_scan_tasks_count: string().required(),
});

async function validateTaskList(result) {
  return object()
    .shape({
      app_protection_tasks: array().of(AppProtectionTaskSchema),
      app_protection_tasks_metadata: AppProtectionTasksMetadataSchema,
    })
    .validate(result);
}

export const app_protection_tasks = {
  async list(mobile_app_id) {
    if (!mobile_app_id) {
      throw new Error('Invalid application id provided');
    }

    return fetch(`${config.sevenhellApiBaseUrl}/v2/mobile_apps/${mobile_app_id}/app_protection_tasks`)
      .then(parse)
      .then(result)
      .then(validateTaskList);
  },
};

export async function patch(id, params) {
  if (!id || !id.length) {
    throw new Error('An invalid mobile app id was provided.');
  }

  if (!params) {
    throw new Error('Either is_enterprise_internal or linked_app_store_mobile_app_id should be provided.');
  }

  // Nullify undefined values for Sevenhell to handle them correctly
  if (typeof params.is_enterprise_internal === 'undefined' || params.is_enterprise_internal === null) {
    delete params['is_enterprise_internal'];
  }

  if (typeof params.is_enterprise_internal !== 'undefined' && params.is_enterprise_internal) {
    params['linked_app_store_mobile_app_id'] = null;
  }

  return fetch(`${config.sevenhellApiBaseUrl}/v2/mobile_apps/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(params),
  })
    .then(parse)
    .then(result);
}
