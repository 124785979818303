import { Observable } from '@apollo/client';
import { execute, validate } from 'graphql';
import { addMockFunctionsToSchema, makeExecutableSchema } from 'graphql-tools';
import buildResolver from './buildResolver';

export { ApolloLinkSchemaRestError } from './resolvers';
export { default as useQuery } from './useQuery';
export { default as useLazyQuery } from './useLazyQuery';

/**
 * RestLink is an apollo-link for communicating with REST services using GraphQL on the client-side
 */
export default function linkSchemaRest(options) {
  const { schema, mockResolvers } = options;

  const { definitions } = schema;

  const resolver = buildResolver(definitions, options);

  const executableSchema = makeExecutableSchema({
    typeDefs: schema,
    resolvers: resolver,
    resolverValidationOptions: {
      requireResolversForAllFields: true,
    },
  });

  // $FlowFixMe
  return {
    request(operation, forward) {
      const { query, variables } = operation;

      if (mockResolvers) {
        addMockFunctionsToSchema({
          schema: executableSchema,
          mocks: mockResolvers,
          preserveResolvers: false,
        });
      }

      if (forward) {
        throw new Error(
          `@dt/apollo-link-schema-rest must be the last link in the chain and cannot forward anything at the moment`,
        );
      }

      const validationErrors = validate(executableSchema, query);
      if (validationErrors.length > 0) {
        // $FlowFixMe - Readonly errors vs validationErrors
        return Observable.of({ errors: validationErrors });
      }

      return new Observable(observer => {
        Promise.resolve(
          execute({
            schema: executableSchema,
            document: query,
            contextValue: { rest: { db: {} } },
            variableValues: variables,
          }),
        )
          .then(result => {
            observer.next({ data: result.data, errors: result.errors });
            observer.complete();
          })
          .catch(err => {
            if (err.name === 'AbortError') return;
            if (err.result && err.result.errors) {
              observer.next(err.result);
            }
            observer.error(err);
          });
      });
    },
  };
}
