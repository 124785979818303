import React, { memo } from 'react';
import { Tooltip } from '@mui/material';

/*
 * Render an asset's type icon.
 * Uses defined backend data for rendering.
 *
 * @param asset_type_icon_url - Backend asset type icon url
 * @param asset_type_name - Backend asset type name
 */
const AssetsTypeIconComponent = function AssetsTypeIcon({
  iconSize = 36,
  asset_type_icon_url,
  asset_type_name,
  style,
}) {
  return (
    <Tooltip disableInteractive title={asset_type_name}>
      <img height={iconSize} width={iconSize} style={style} src={asset_type_icon_url} alt={`${asset_type_name} Icon`} />
    </Tooltip>
  );
};

export const AssetsTypeIcon = memo(AssetsTypeIconComponent);
