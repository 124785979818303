import React, { memo } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { CircularProgress, Grid } from '@mui/material';
import { Message } from '@components';

const InventoryTableExportComponent = function InventoryTableExport({ error, loading, completedCount, totalCount }) {
  return (
    <Grid container spacing={1}>
      {loading && (
        <Grid item xs={12}>
          <Message m={0} variant={'info'} icon={<CircularProgress size={16} />} message={'Exporting...'} />
        </Grid>
      )}
      {loading && totalCount > 0 && (
        <Grid item xs={12}>
          <Message m={0} variant={'info'} message={`${completedCount} out ${totalCount} completed.`} />
        </Grid>
      )}
      {error ? (
        <Grid item xs={12}>
          <Message
            m={0}
            variant={'error'}
            icon={<ErrorIcon />}
            message={'An error occurred while exporting your assets.'}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default memo(InventoryTableExportComponent);
