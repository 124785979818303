import React from 'react';
import { FormControl, FormHelperText, Grid, MenuItem, Select } from '@mui/material';
import { UTCToLocalTime, hoursOfDay, roundHour } from '@dt/date';

function TimeInput({ time, onChange, showLocalTime = false, variant, classes }) {
  return (
    <Grid container>
      <FormControl component="fieldset" aria-label="time-choice">
        <Select
          value={roundHour(time)}
          onChange={e => onChange(e.target.value)}
          displayEmpty
          MenuProps={{ disablePortal: true }}
          variant={variant}
          classes={classes}
        >
          <MenuItem value=""> --:-- </MenuItem>
          {hoursOfDay.map(([h, offset]) => (
            <MenuItem value={h + offset} key={h + offset}>
              {h} UTC
            </MenuItem>
          ))}
        </Select>
        {showLocalTime && <FormHelperText>{time.length ? `Local time: ${UTCToLocalTime(time)}` : ' '}</FormHelperText>}
      </FormControl>
    </Grid>
  );
}

export default React.memo(TimeInput);
