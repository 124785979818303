import React, { useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';
import Text from './Text';

const useStyles = makeStyles(() => ({
  tabContainer: {
    overflowX: 'auto',
    paddingBlockStart: '1em',
  },
  tabContainerContent: {
    padding: 0,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
  tab: {
    maxWidth: 'inherit',
    color: palette.gray30,
  },
  selectedTab: {
    color: palette.brand35,
    borderBottom: `3px solid ${palette.brand35}`,
  },
}));

export default function QueryAndResponse({ scanTrace }) {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState('query');

  return (
    <Box borderRadius={0} px={3} py={1} style={{ width: '100%', padding: 0 }}>
      <Tabs
        value={activeTab}
        onChange={(e, newValue) => setActiveTab(newValue)}
        indicatorColor="primary"
        style={{
          color: palette.brand35,
          borderBottom: `2px solid ${palette.gray30}`,
        }}
        aria-label="query and response details"
      >
        <Tab
          value="query"
          label="Query"
          className={classes.tab}
          classes={{
            selected: classes.selectedTab,
          }}
        />

        <Tab
          value="response"
          label="Response"
          className={classes.tab}
          classes={{
            selected: classes.selectedTab,
          }}
        />
      </Tabs>

      <div className={classes.tabContainer}>
        <Text variant="code" className={classes.tabContainerContent}>
          {activeTab === 'query'
            ? scanTrace?.query
              ? scanTrace?.query
              : 'Currently unavailable. Please check back later!'
            : null}

          {activeTab === 'response'
            ? scanTrace?.response
              ? scanTrace?.response
              : 'Currently unavailable. Please check back later!'
            : null}
        </Text>
      </div>
    </Box>
  );
}
