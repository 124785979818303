import React, { memo } from 'react';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';
import HostedOnIcon from './HostedOnIcon';
import { getDisplayNameForHostedOn } from './TagHostedOn';

const useStyles = makeStyles({
  label: {
    width: 'auto',
    padding: '0 6px',
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    backgroundColor: palette.gray45,
    color: palette.gray30,
  },
  icon: {
    marginRight: 2,
    padding: 2,
  },
  text: {
    padding: '4px 2px',
  },
});

function CountHostedOnType({ hosted_on, count }) {
  const classes = useStyles();

  const hostedName = getDisplayNameForHostedOn(hosted_on);

  return (
    <Box m="4px" display="inline-block">
      <Tooltip title={`${count} ${hostedName}`} aria-label={`${count} ${hostedName}`}>
        <Box className={classes.label}>
          <HostedOnIcon hosted_on={hosted_on} />
          <span className={classes.text}>{`${count} ${hostedName}`}</span>
        </Box>
      </Tooltip>
    </Box>
  );
}

export default memo(CountHostedOnType);
