import React, { memo } from 'react';
import { palette } from '@dt/theme';

function APISecureIcon({ foregroundColor = palette.brand30, backgroundColor = palette.white }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="1.4"
      clipRule="evenodd"
      viewBox="0 0 160 160"
      width="100%"
      // This is important for IE 11
      style={{ maxHeight: 'inherit ' }}
    >
      <g fillRule="nonzero">
        <path
          d="M160 127.19C160.001 131.499 159.154 135.766 157.505 139.747C155.857 143.729 153.44 147.346 150.393 150.393C147.346 153.44 143.729 155.857 139.747 157.505C135.766 159.154 131.499 160.001 127.19 160H32.81C24.1082 160 15.7629 156.543 9.60983 150.39C3.45676 144.237 0 135.892 0 127.19L0 32.8C0.00265164 24.1 3.46058 15.7572 9.61336 9.60629C15.7661 3.45539 24.11 1.12044e-06 32.81 1.52453e-06H127.19C131.498 -0.00131195 135.764 0.846116 139.745 2.49388C143.726 4.14165 147.343 6.55747 150.39 9.60336C153.436 12.6493 155.853 16.2655 157.502 20.2457C159.151 24.2258 160 28.4918 160 32.8V127.19Z"
          fill={backgroundColor}
        />
        <path
          d="M102.23 105L82.0002 79.52V54.82C84.8998 54.3191 87.506 52.7485 89.3034 50.4188C91.1008 48.089 91.9586 45.1696 91.7073 42.2378C91.4559 39.306 90.1137 36.5752 87.9459 34.5854C85.7782 32.5955 82.9428 31.4915 80.0002 31.4915C77.0577 31.4915 74.2222 32.5955 72.0545 34.5854C69.8867 36.5752 68.5445 39.306 68.2932 42.2378C68.0418 45.1696 68.8996 48.089 70.6971 50.4188C72.4945 52.7485 75.1006 54.3191 78.0002 54.82V79.55L57.4702 105H36.4702V128.5H60.0002V108.24L80.0002 83.43L100 108.62V128.5H123.5V105H102.23Z"
          fill={foregroundColor}
        />
      </g>
    </svg>
  );
}

export default memo(APISecureIcon);
