import { AssetTypes } from '@dt/horizon-api';
import immer from 'immer';
import {
  InventorySearchcloudResourceTypesReceived,
  inventoryCreateSearchFailed,
  inventoryCreateSearchSucceed,
  inventorySearchCloudProvidersReceived,
  inventorySearchCloudResourceTypesRecieved,
  inventorySearchModifyText,
  inventorySearchMounted,
  inventorySearchToggleAnAssetType,
  inventorySearchToggleCloudProvider,
  inventorySearchToggleCloudResourceCategory,
  inventorySearchToggleCloudResourceType,
} from './actions';

const initialState = {
  cloud_resource_types: {
    ids: [],
    for_category: {},
  },
  asset_types: [
    {
      enum: AssetTypes.RESTFUL_API,
      name: 'Restful APIs',
    },
    {
      enum: AssetTypes.NETWORK_SERVICE,
      name: 'Network Services',
    },
    {
      enum: AssetTypes.WEB_APPLICATION,
      name: 'WebApps',
    },
    {
      enum: AssetTypes.CLOUD_RESOURCE,
      name: 'Cloud resources',
    },
  ],
  cloudProviderList: [
    {
      enum: 'AMAZON_WEB_SERVICES',
      name: 'Amazon Web Services',
    },
    {
      enum: 'AZURE',
      name: 'Azure',
    },
    {
      enum: 'GOOGLE_CLOUD_PLATFORM',
      name: 'Google Cloud Platform',
    },
    {
      enum: 'APIGEE',
      name: 'Apigee',
    },
    {
      enum: 'MULESOFT',
      name: 'Mulesoft',
    },
  ],
  query: {
    asset_types: [],
    cloud_resource_types: [],
    hosted_on_cloud_providers: [],
    text: null,
  },
  queryList: {},
  lastQueryId: null,
};

export default immer((draft, action) => {
  if (action.type === inventorySearchMounted.toString()) {
    resetSearchQuery(draft);
  }

  if (action.type === inventoryCreateSearchSucceed.toString()) {
    const { asset_search_id } = action.payload;
    draft.queryList[asset_search_id] = draft.query;
    draft.lastQueryId = asset_search_id;
  }

  if (action.type === inventoryCreateSearchFailed.toString()) {
    resetSearchQuery(draft);
  }

  if (action.type === InventorySearchcloudResourceTypesReceived.toString()) {
    const { cloudResourceTypes } = action.payload;
    draft.cloud_resource_types.ids = cloudResourceTypes;
    draft.cloud_resource_types.for_category = cloudResourceTypes.reduce((categories, cloudResourceType, index) => {
      const { category_enum, category_name } = cloudResourceType;
      if (!categories[category_enum]) {
        categories[category_enum] = {
          enum: category_enum,
          name: category_name,
          ids: [index],
        };
      } else {
        categories[category_enum].ids.push(index);
      }
      return categories;
    }, {});
  }

  /**
   * toggle selected asset type
   */
  if (action.type === inventorySearchToggleAnAssetType.toString()) {
    const selectedAssetType = action.payload;

    if (selectedAssetType === 'CLOUD_RESOURCE') {
      if (isAllCloudResourceTypesSelected(draft)) {
        deSelectAllCloudResourceTypes(draft);
      } else {
        selectAllCouldResourceTypes(draft);
      }
    } else if ((draft.query.asset_types || []).includes(selectedAssetType)) {
      draft.query.asset_types = (draft.query.asset_types || []).filter(item => item !== selectedAssetType);
    } else {
      draft.query.asset_types = (draft.query.asset_types || []).concat(selectedAssetType);
    }
  }

  /**
   * toggle all cloud resource types for selected category
   */
  if (action.type === inventorySearchToggleCloudResourceCategory.toString()) {
    const selectedCategory = action.payload;

    if (isAllCloudResourceTypesSelectedForCategory(draft, selectedCategory)) {
      deselectAllCouldResourceTypesForCategory(draft, selectedCategory);
    } else {
      selectAllCouldResourceTypesForCategory(draft, selectedCategory);
    }
  }

  /**
   * toggle selected cloud resource type
   */
  if (action.type === inventorySearchToggleCloudResourceType.toString()) {
    const selectedCloudResourceType = action.payload;

    if (isCloudResourceTypeSelected(draft, selectedCloudResourceType)) {
      deselectCloudResourceType(draft, selectedCloudResourceType);
    } else {
      selectCouldResourceType(draft, selectedCloudResourceType);
    }
  }

  if (action.type === inventorySearchCloudResourceTypesRecieved.toString()) {
    const selectedCloudResourceTypes = action.payload;
    draft.query.cloud_resource_types = [];
    selectedCloudResourceTypes.forEach(item => {
      selectCouldResourceType(draft, item);
    });
  }

  if (action.type === inventorySearchToggleCloudProvider.toString()) {
    const selectedEnvironment = action.payload;
    if ((draft.query.hosted_on_cloud_providers || []).includes(selectedEnvironment)) {
      draft.query.hosted_on_cloud_providers = (draft.query.hosted_on_cloud_providers || []).filter(
        environment => environment !== selectedEnvironment,
      );
    } else {
      draft.query.hosted_on_cloud_providers = (draft.query.hosted_on_cloud_providers || []).concat(selectedEnvironment);
    }
  }

  if (action.type === inventorySearchCloudProvidersReceived.toString()) {
    const selectedEnvironments = action.payload;
    draft.query.hosted_on_cloud_providers = selectedEnvironments;
  }

  if (action.type === inventorySearchModifyText.toString()) {
    const { text } = action.payload;
    draft.query.text = text;
  }
}, initialState);

function resetSearchQuery(draft) {
  draft.query.asset_types = [];
  draft.query.cloud_resource_types = [];
  draft.query.hosted_on_cloud_providers = [];
  draft.query.text = null;
  draft.lastQueryId = null;
}

function isCloudResourceTypeSelected(draft, cloudResourceType) {
  return draft.query.cloud_resource_types.includes(cloudResourceType);
}

function deselectCloudResourceType(draft, cloudResourceType) {
  draft.query.cloud_resource_types = (draft.query.cloud_resource_types || []).filter(
    item => item !== cloudResourceType,
  );
  if (!isAnyCloudResourceTypesSelected(draft)) {
    deSelectAllCloudResourceTypes(draft);
  }
}

function selectCouldResourceType(draft, cloudResourceType) {
  selectCloudResourceAsset(draft);
  draft.query.cloud_resource_types = (draft.query.cloud_resource_types || []).concat(cloudResourceType);
}

function isAllCloudResourceTypesSelected(draft) {
  return !draft.cloud_resource_types.ids.filter(type => !draft.query.cloud_resource_types.includes(type.enum)).length;
}

function isAnyCloudResourceTypesSelected(draft) {
  return draft.cloud_resource_types.ids.filter(type => draft.query.cloud_resource_types.includes(type.enum)).length;
}

function deSelectAllCloudResourceTypes(draft) {
  draft.query.asset_types = (draft.query.asset_types || []).filter(item => item !== 'CLOUD_RESOURCE');
  draft.query.cloud_resource_types = [];
}

function selectAllCouldResourceTypes(draft) {
  selectCloudResourceAsset(draft);
  draft.query.cloud_resource_types = draft.cloud_resource_types.ids.reduce((list, item) => {
    list.push(item.enum);
    return list;
  }, []);
}

function isAllCloudResourceTypesSelectedForCategory(draft, categoryEnum) {
  if (!draft.cloud_resource_types.for_category[categoryEnum]) {
    return true;
  }
  return !draft.cloud_resource_types.for_category[categoryEnum].ids.filter(
    type => !draft.query.cloud_resource_types.includes(draft.cloud_resource_types.ids[type].enum),
  ).length;
}

function deselectAllCouldResourceTypesForCategory(draft, categoryEnum) {
  const cloudResourceTypesIdsForCategory = (draft.cloud_resource_types.for_category[categoryEnum] || {}).ids || [];
  const cloudResourceTypesForCategory = cloudResourceTypesIdsForCategory.reduce((list, id) => {
    return list.concat(draft.cloud_resource_types.ids[id].enum);
  }, []);
  draft.query.cloud_resource_types = draft.query.cloud_resource_types.filter(type => {
    return !cloudResourceTypesForCategory.includes(type);
  });
  if (!isAnyCloudResourceTypesSelected(draft)) {
    deSelectAllCloudResourceTypes(draft);
  }
}

function selectAllCouldResourceTypesForCategory(draft, categoryEnum) {
  const cloudResourceTypesIdsForCategory = (draft.cloud_resource_types.for_category[categoryEnum] || {}).ids || [];
  const cloudResourceTypesForCategory = cloudResourceTypesIdsForCategory.reduce((list, id) => {
    return list.concat(draft.cloud_resource_types.ids[id].enum);
  }, []);
  selectCloudResourceAsset(draft);
  draft.query.cloud_resource_types = (draft.query.cloud_resource_types || []).concat(cloudResourceTypesForCategory);
}

function selectCloudResourceAsset(draft) {
  if (!(draft.query.asset_types || []).includes('CLOUD_RESOURCE')) {
    draft.query.asset_types = (draft.query.asset_types || []).concat('CLOUD_RESOURCE');
  }
}
