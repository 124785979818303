import React, { memo } from 'react';
import ShareIcon from '@mui/icons-material/Share';
import { Button } from '@mui/material';
import { useMutation } from '@apollo/client';
import scoped_access_tokens from '@dt/graphql-support/sevenhell/scoped_access_tokens';
import Banner from './Banner';
import { SharedLinksButtonCommon } from './SharedLinksButtonCommon';

/*
 * NOTE: Use the `SharedLinksButton` facade component.
 *
 * Shared links button used to share openscan mobile applications.
 *
 * @param openscan_mobile_application_id - Used with the graphql create when creating a shared link
 * @param openscan_mobile_application_name - Used to provide the share dialog some context to the user
 *
 * @example
 *     <SharedLinksButtonOpenscanMobileApplication
 *       openscan_mobile_application_id={mobile_application.id}
 *       openscan_mobile_application_name={mobile_application.name} />
 */
const SharedLinksButtonOpenscanMobileApplicationComponent = function SharedLinksButtonOpenscanMobileApplication({
  openscan_mobile_application_id,
  openscan_mobile_application_name,
}) {
  const [createSharedLink, { loading, error }] = useMutation(scoped_access_tokens.create_for_openscan_app_id);

  const handleOnCreate = async validity_duration => {
    const { data } = await createSharedLink({
      variables: {
        body: {
          validity_duration: validity_duration,
        },
        openscan_app_id: openscan_mobile_application_id,
      },
    });

    const access_token = data?.scoped_access_token_for_openscan_app_id?.access_token;
    return access_token
      ? `${window.location.origin}/openscan/share/${access_token}/app/${openscan_mobile_application_id}`
      : '';
  };

  return (
    <SharedLinksButtonCommon
      error={error}
      loading={loading}
      renderButton={(toggleDialog, disabled) => (
        <Button variant="contained" size="small" onClick={toggleDialog} startIcon={<ShareIcon />} disabled={disabled}>
          Secure Share
        </Button>
      )}
      onCreate={handleOnCreate}
      title={`Create a Secure Link to ${openscan_mobile_application_name}'s Security Results`}
    >
      <Banner status="warning" inline icon={<></>}>
        The secure link will allow temporary access to this application and its issues.
      </Banner>
    </SharedLinksButtonCommon>
  );
};

export const SharedLinksButtonOpenscanMobileApplication = memo(SharedLinksButtonOpenscanMobileApplicationComponent);
