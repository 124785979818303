import React, { memo, useState } from 'react';
import AutoRenewIcon from '@mui/icons-material/Autorenew';
import CheckCircle from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { Drawer, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Message } from '@components';
import { useQuery } from '@dt/apollo-link-schema-rest';
import { BulkActionsStatusEnumValues } from '@dt/graphql-support/enums';
import bulk_actions from '@dt/graphql-support/horizon/bulk_actions';
import { Banner, ConfirmMessage, StatusMessageEnumValues, Text } from '@dt/material-components';
import { useTableFilter } from '../../../hooks/use_table_filter';
import InventoryTable from '../inventory_table/InventoryTable';
import { columnEnum } from '../inventory_table/InventoryTable';
import InventoryTableCardEditAction from './InventoryTableEditTriageAction';
import useBulkActionMoveToAssetGroup from './use_bulk_action_move_to_asset_group';

const useStyles = makeStyles({
  content: {
    padding: 24,
    width: '80%',
    maxWidth: 1200,
    display: 'flex',
  },
  ongoingIcon: {
    animation: '$rotation 4s infinite linear',
  },
  '@keyframes rotation': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(359deg)',
    },
  },
});

const InventoryTableCardEditDrawerComponent = function InventoryTableCardEditDrawer({ open, onClose }) {
  const classes = useStyles();

  const [checkedIds, setCheckedIds] = useState([]);
  const [allAssetsCheckedCount, setAllAssetsCheckedCount] = useState(0);
  const [assetGroupId, setAssetGroupId] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [create, mutationResult] = useBulkActionMoveToAssetGroup(checkedIds, assetGroupId);

  const { data: moveStatusData, error: moveStatusError } = useQuery(
    bulk_actions.assets.move_to_asset_group_actions.list,
    {
      variables: {
        filter_by_status: BulkActionsStatusEnumValues.ONGOING,
      },
    },
  );

  const hasOngoingMoves = Boolean(
    moveStatusData?.bulk_action_move_to_asset_group_list.pagination_information.total_count,
  );

  const onApply = () => {
    setCheckedIds([]);
    setAllAssetsCheckedCount(0);
  };

  const { FilterComponent, userInterfaceFilter } = useTableFilter({
    assetsSearchFilterValuesQueryParams: {},
    filterEnums: [
      'filter_by_text',
      'filter_by_asset_type',
      'filter_by_discovered_via',
      'filter_by_cloud_provider',
      'filter_by_asset_group_id',
      'filter_by_is_shadow',
      'filter_by_asset_tags',
    ],
    readOnlyFilters: null,
    enableBookmarking: false,
    disabled: hasOngoingMoves,
    onApply,
  });

  return (
    <Drawer
      anchor="right"
      transitionDuration={{ enter: 300, exit: 400 }}
      classes={{ paper: classes.content }}
      open={open}
      disableEnforceFocus
      aria-label={'edit-assets-drawer'}
      onClose={() => {
        setAssetGroupId(null);
        setCheckedIds([]);
        setAllAssetsCheckedCount(0);
        setShowAlert(false);
        onClose();
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container alignContent={'space-between'}>
            <Grid item xs={12} md={9} onClick={() => setShowAlert(false)}>
              <FilterComponent />
            </Grid>
            <Grid item xs={12} md={3}>
              <InventoryTableCardEditAction
                selectedAssetsCount={allAssetsCheckedCount ? allAssetsCheckedCount : checkedIds.length}
                disabled={mutationResult.loading}
                onMoveToAssetGroup={assetGroupId => {
                  setAssetGroupId(assetGroupId);
                  setShowConfirm(true);
                  setShowAlert(false);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {showConfirm && (
          <Grid item xs={12}>
            <ConfirmMessage
              message={`This action will affect ${
                allAssetsCheckedCount ? `all ${allAssetsCheckedCount}` : checkedIds?.length
              } selected assets. Are you sure you want to continue?`}
              onCancel={() => {
                setShowConfirm(false);
              }}
              onConfirm={() => {
                setShowConfirm(false);
                setCheckedIds([]);
                setShowAlert(true);
                // For mapping of singular key names from filters in asset list
                // to plural key names expected by bulk_actions endpoint
                const bulkFilterPayload = {};
                if (userInterfaceFilter?.filter_by_asset_type) {
                  bulkFilterPayload.filter_by_asset_types = userInterfaceFilter.filter_by_asset_type;
                }
                if (userInterfaceFilter?.filter_by_asset_group_id) {
                  bulkFilterPayload.filter_by_asset_group_ids = userInterfaceFilter.filter_by_asset_group_id;
                }
                if (userInterfaceFilter?.filter_by_cloud_provider) {
                  bulkFilterPayload.filter_by_cloud_providers = userInterfaceFilter.filter_by_cloud_provider;
                }
                if (userInterfaceFilter?.filter_by_discovered_via) {
                  bulkFilterPayload.filter_by_discovered_vias = userInterfaceFilter.filter_by_discovered_via;
                }
                if (userInterfaceFilter?.filter_by_is_shadow) {
                  bulkFilterPayload.filter_by_is_shadow = userInterfaceFilter.filter_by_is_shadow;
                }
                if (userInterfaceFilter?.filter_by_text) {
                  bulkFilterPayload.filter_by_text = userInterfaceFilter.filter_by_text;
                }

                {
                  allAssetsCheckedCount
                    ? create({
                        variables: {
                          body: {
                            move_to_asset_group_id: assetGroupId,
                            perform_on_assets_matching: bulkFilterPayload,
                          },
                        },
                      })
                    : create({
                        variables: {
                          body: {
                            move_to_asset_group_id: assetGroupId,
                            perform_on_assets_with_ids: checkedIds,
                          },
                        },
                      });
                }
                setAllAssetsCheckedCount(0);
              }}
            />
          </Grid>
        )}
        {mutationResult.loading && !assetGroupId && (
          <Grid item xs={12}>
            <Message
              m={0}
              variant={'info'}
              icon={<AutoRenewIcon className={classes.ongoingIcon} />}
              message={'Removing assets from all groups...'}
            />
          </Grid>
        )}
        {mutationResult.loading && assetGroupId && (
          <Grid item xs={12}>
            <Message
              m={0}
              variant={'info'}
              icon={<AutoRenewIcon className={classes.ongoingIcon} />}
              message={'Moving assets to asset group...'}
            />
          </Grid>
        )}
        {showAlert && mutationResult.error ? (
          <Grid item xs={12}>
            <Message
              m={0}
              variant={'error'}
              icon={<ErrorIcon />}
              message={mutationResult.error.message.replace('GraphQL error: ', '')}
            />
          </Grid>
        ) : null}
        {showAlert && moveStatusError ? (
          <Grid item xs={12}>
            <Message
              m={0}
              variant={'error'}
              icon={<ErrorIcon />}
              message={moveStatusError.message.replace('GraphQL error: ', '')}
            />
          </Grid>
        ) : null}
        {showAlert && mutationResult.data ? (
          <Grid item xs={12}>
            <Message
              m={0}
              variant={'success'}
              icon={<CheckCircle />}
              message={
                assetGroupId
                  ? 'Selected assets moved to the asset group.'
                  : 'Selected assets removed from all asset groups.'
              }
            />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          {hasOngoingMoves && (
            <Banner inline status={StatusMessageEnumValues.PROCESSING}>
              <Text variant="body" style={{ marginBottom: 0, marginTop: '4px' }}>
                Removing assets from all groups...
              </Text>
            </Banner>
          )}
        </Grid>
        <Grid item xs={12}>
          <InventoryTable
            columns={[columnEnum.asset_name, columnEnum.hosted_on, columnEnum.discovered_via, columnEnum.asset_group]}
            filters={userInterfaceFilter}
            emptyStateVariant={'no-match'}
            checkboxSelection={true}
            allAssetsCheckedCount={allAssetsCheckedCount}
            setAllAssetsCheckedCount={setAllAssetsCheckedCount}
            checkedIds={checkedIds}
            onChangeSelection={ids => {
              setShowAlert(false);
              setCheckedIds(ids);
              setAllAssetsCheckedCount(0);
            }}
            isDisabled={hasOngoingMoves}
            isCard
            clearCheckboxOnPageChange={false}
          />
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default memo(InventoryTableCardEditDrawerComponent);
