import startCase from 'lodash/startCase';
import numeral from 'numeral';

function localeStringSort(a, b) {
  if (!a || typeof a.localeCompare !== 'function') {
    return 1;
  }
  return a.localeCompare(b);
}

export function humanReadable(item) {
  if (item === 'IOS') {
    return 'iOS';
  }
  return item
    .toLowerCase()
    .replace(/_/g, ' ')
    .trim()
    .replace(/\b\w/g, letter => letter.toUpperCase());
}

export function stringFromParametricRequest(endpoint, params = {}) {
  const keys = Object.keys(params).filter(Boolean).sort(localeStringSort);
  const values = keys
    .map(k => params[k])
    .filter(Boolean)
    .sort(localeStringSort);
  return `${endpoint}-${keys.join('-')}-${values.join('-')}`;
}

export function numberFrom(string) {
  return numeral(string).value();
}

export function encodeURIFormComponent(string) {
  // Encode spaces in params as +
  // See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent

  return encodeURIComponent(string).replace(/%20/g, '+');
}

// From: https://stackoverflow.com/a/13627586
export function ordinalize(target) {
  const i = parseInt(target, 10);
  const j = i % 10;
  const k = i % 100;

  if (j == 1 && k != 11) {
    return i + 'st';
  }
  if (j == 2 && k != 12) {
    return i + 'nd';
  }
  if (j == 3 && k != 13) {
    return i + 'rd';
  }
  return i + 'th';
}

export function filenameFromURL(url) {
  try {
    const u = new URL(url);
    const paths = u.pathname.split('/');
    return paths[paths.length - 1];
  } catch (e) {
    return '';
  }
}

export function getAppVersion(app) {
  // $FlowFixMe
  const app_version = app?.most_recent_scan?.app_version || app?.version_id;
  if (app_version && app_version.match(/[0-9]/)) {
    return app_version;
  } else {
    return 'N/A';
  }
}

export function getBundleId(app) {
  return app?.bundle_id || 'N/A';
}

export function getToolkitAppCardFormat(app) {
  return app ? `${humanReadable(app.platform)} | v${getAppVersion(app)}` : `v${getAppVersion(app)}`;
}

export function getInventoryAppSummary(app) {
  return app?.bundle_id || '';
}

export function subscriptionToFriendlyString(sub) {
  switch (sub.toLowerCase()) {
    case 'p1_alerts':
      return 'Security P1';
    case 'static':
      return 'Baseline';
    case 'dynamic':
      return 'Enterprise';
    case 'app_logic':
      return 'Prime';
    case 'no_subscription':
      return 'None';
    default:
      return startCase(sub.toLowerCase());
  }
}
