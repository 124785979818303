import React from 'react';

export const getDomainFromUrl = url => {
  const anchor = global.document.createElement('a');
  anchor.href = url;
  return anchor.hostname;
};

export const ExternalLogo = ({ domain, alt, title, size }) => {
  return (
    <div title={title} style={{ width: size, height: size }}>
      <img alt={alt} src={`https://logo.clearbit.com/${domain}`} width={size} height={size} />
    </div>
  );
};
