import React, { memo } from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import TableCell from '@mui/material/TableCell';
import AssetHostedOnTag from './AssetHostedOnTag';

const AssetTableCellHostedOn = props => {
  if (props.loading) {
    return (
      <TableCell>
        <Box display={'flex'} alignItems={'center'}>
          <Skeleton animation="wave" variant="text" height={40} width={100} />
        </Box>
      </TableCell>
    );
  }

  const { name, iconUrl, maxWidth } = props;

  return (
    <TableCell>
      <AssetHostedOnTag name={name} iconUrl={iconUrl} maxWidth={maxWidth} />
    </TableCell>
  );
};

export default memo(AssetTableCellHostedOn);
