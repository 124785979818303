import React from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  label: {
    position: 'relative',
    top: -30,
    textAlign: 'center',
  },
});

function SemiCircularDonutChart({ total, completed, size, children }) {
  const classes = useStyles();

  return (
    <Grid container justifyContent={'center'}>
      <Grid item xs={12} style={{ maxHeight: size || 200 }}>
        <svg width="100%" height="100%" viewBox="0 0 42 20">
          <circle
            cx="21"
            cy="21"
            r="15.91549430918954"
            fill="transparent"
            stroke={`${total.color}`}
            strokeWidth="6"
            aria-label={`total-${total.count}-${completed.color}`}
          ></circle>
          <circle
            cx="21"
            cy="21"
            r="15.91549430918954"
            fill="transparent"
            stroke={`${completed.color}`}
            strokeWidth="6"
            strokeDasharray={`${(completed.count * 50) / total.count + 50} 100`}
            aria-label={`completed-${total.count}-${completed.color}`}
          ></circle>
        </svg>
      </Grid>
      <Grid item>
        <Grid container className={classes.label}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default React.memo(SemiCircularDonutChart);
