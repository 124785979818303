import React from 'react';
import PolicyIcon from '@mui/icons-material/AssignmentTurnedIn';
import { Avatar, Card, CardContent, Grid, Tooltip } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import DatatheoremLogo from '@dt/brand/logo.png';
import { Text } from '@dt/material-components';
import { palette } from '@dt/theme';
import { Link } from '@reach/router';

/*
 * Shows the user their policy in card format.
 *
 * @param loading - Whether or not to show loading state.
 * @param productBasePath - Used to maintain navigational context for the user.
 * @param policy - Policy this component should show.
 */
const PolicyCardComponent = function PolicyCard(props) {
  if (props.loading) {
    return (
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={10}>
              <Grid container alignItems="flex-start" spacing={1} wrap="nowrap">
                <Grid item>
                  <Skeleton variant="circular" animation="wave" width={40} height={40} />
                </Grid>
                <Grid item xs={10}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Skeleton animation="wave" width={'30%'} />
                    </Grid>
                    <Grid item xs={12}>
                      <Skeleton animation="wave" width={'20%'} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={2}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Skeleton animation="wave" width={'50%'} />
                </Grid>

                <Grid item xs={12}>
                  <Skeleton animation="wave" width={'30%'} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  const baseUrl =
    props.productBasePath && !props.isV2
      ? `${props.productBasePath}/policies`
      : props.productBasePath && props.isV2
        ? `/policies/v2${props.productBasePath}`
        : !props.productBasePath && props.isV2
          ? `/policies/v2`
          : '/policies';

  const PolicyCardContent = (
    <Card elevation={props.isV2 ? 0 : 1} style={{ borderRadius: props.isV2 ? 8 : 4 }}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={10}>
            <Grid container alignItems="flex-start" spacing={1} wrap="nowrap">
              <Grid item>
                <Avatar style={{ backgroundColor: palette.gray45 }}>
                  <PolicyIcon style={{ color: palette.gray20 }} />
                </Avatar>
              </Grid>

              <Grid item xs={10}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Text variant="body" component="div" noWrap={true}>
                      {props.policy.name}{' '}
                      {props.policy.is_special_dt_policy && (
                        <Tooltip
                          disableInteractive
                          title="This policy has been crafted by Data Theorem's security experts."
                        >
                          <img
                            style={{
                              width: 18,
                              height: 18,
                              verticalAlign: 'middle',
                              objectFit: 'cover',
                            }}
                            src={DatatheoremLogo}
                          />
                        </Tooltip>
                      )}
                    </Text>
                  </Grid>

                  <Grid item xs={12}>
                    <Text variant="caption" component="div">
                      {`Created by ${
                        (props.policy.created_by_actor_name && props.policy.created_by_actor_name) || 'Unknown'
                      } on ${new Date(props.policy.date_created).toLocaleDateString()}`}
                    </Text>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={2}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Text variant="body" component="div">
                  {props.policy.enabled_policy_rules_count} rules enabled
                </Text>
              </Grid>

              <Grid item xs={12}>
                <Text variant="body" component="div">
                  {props.policy.protected_asset_groups_count} asset group
                  {props.policy.protected_asset_groups_count !== 1 ? 's' : ''} protected
                </Text>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  return <Link to={`${baseUrl}/${props.policy.id}`}>{PolicyCardContent}</Link>;
};

export default React.memo(PolicyCardComponent);
