import React, { memo } from 'react';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';

const useStyles = makeStyles({
  container: {
    // region fill
    width: ({ fill }) => (fill ? '100%' : ''),
    height: ({ fill }) => (fill ? '100%' : ''),
    // endregion fill

    display: 'flex',
  },

  badge: {
    // region fill
    width: ({ fill }) => (fill ? '100%' : ''),
    height: ({ fill }) => (fill ? '100%' : ''),
    // endregion fill

    backgroundColor: ({ backgroundColor }) => backgroundColor || palette.brand,

    // region borderRadius
    borderTopLeftRadius: ({ radius }) => radius,
    borderBottomLeftRadius: ({ radius }) => radius,
    borderTopRightRadius: ({ radius }) => radius,
    borderBottomRightRadius: ({ radius }) => radius,
    // endregion borderRadius

    color: ({ color }) => color || '#fff',
    // region size
    fontSize: ({ size }) => (size === 'large' ? '11px' : '9px'),
    fontWeight: 'bold',
    lineHeight: ({ size }) => (size === 'large' ? '11px' : '9px'),
    padding: ({ size }) => (size === 'large' ? '3px 6px 3px 6px' : '2px 4px 2px 4px'),
    // endregion size

    // region uppercase
    textTransform: 'uppercase',
    // endregion uppercase

    whiteSpace: 'nowrap',
  },
});

function Badge(props) {
  const { label } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.container}>
      <div className={classes.badge}>{label}</div>
    </div>
  );
}

Badge.defaultProps = {
  fill: false,
  size: 'large',
  radius: 3,
};
export default memo(Badge);
