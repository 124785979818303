export function values(o) {
  return Object.keys(o)
    .map(k => o[k])
    .filter(Boolean);
}

export function generateTextFile(content, name) {
  const element = document.createElement('a');
  const file = new Blob([content], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = name ? name : 'scan-log.txt';
  document.body && document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
}

/**
 * returns the given number if it isn't NaN, otherwise return 0
 * @param {given number} num
 */
export function NaNOrInfinityAsZero(num) {
  if (Number.isNaN(num)) {
    return 0;
  }
  if (!Number.isFinite(num)) {
    return 0;
  }
  return num;
}

export function safeParse(value) {
  try {
    return value ? JSON.parse(value) : null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function capitalize(str) {
  return (str || '')?.charAt(0).toUpperCase() + (str || '')?.slice(1);
}

export const validateEmail = email => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const formatUtc = date => {
  const dateObj = typeof date === 'string' ? new Date(date) : date;

  const hours = dateObj.getUTCHours().toString().padStart(2, '0');
  const minutes = dateObj.getUTCMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`;
};
