import React, { memo } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import { Avatar, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';
import { AssetTypeEnumValues } from '@dt/graphql-support/enums';
import { AssetTypeDict } from '@dt/material-components';
import { palette } from '@dt/theme';
import { Link } from '@reach/router';
import { AssetsTypeIcon } from '../../components/assets/AssetsTypeIcon';

const assetToLink = {
  [AssetTypeEnumValues.DOMAIN_NAME]: 'network-services',
  [AssetTypeEnumValues.CLOUD_RESOURCE]: 'cloud-resources',
  [AssetTypeEnumValues.RESTFUL_API]: 'assets',
  [AssetTypeEnumValues.WEB_APPLICATION]: 'web-applications',
  [AssetTypeEnumValues.GRAPHQL_API]: 'graphql-apis',
  [AssetTypeEnumValues.KUBERNETES_CLUSTER]: 'assets',
  [AssetTypeEnumValues.KUBERNETES_CLUSTER_COMPONENT]: 'assets',
};

const useStyles = makeStyles({
  avatar: { color: palette.gray20, backgroundColor: palette.gray45 },
});

// Since this component was originally written for `web portal` in order
// to avoid passing `assetPathPrefix` for all existing usages of this
// component we use `/web` as default value
function ConnectedAssets(props) {
  const classes = useStyles();

  if (props.loading) {
    return (
      <Card>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={2}>
                <Skeleton variant="rectangular" width={'100%'} height={28} />
              </Grid>
              <Grid item xs={10}>
                <Skeleton variant="rectangular" width={300} height={28} />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        {props.connected_assets.length > 0 ? (
          props.connected_assets.map((a, i) => {
            const location = a.asset_type === AssetTypeDict.CLOUD_RESOURCE ? a.name : a.url;

            return (
              <Box key={a.id}>
                <Box p="4px 12px" key={a.id} display="flex" alignItems="center">
                  <AssetsTypeIcon
                    key={a.id}
                    asset_type_name={a.asset_type_name}
                    asset_type_icon_url={a.asset_type_icon_url}
                  />
                  <Box p="12px">
                    <Typography>{`${a.asset_type_name} - ${location}`}</Typography>
                  </Box>
                  <Box p="12px" color={palette.gray20} display="flex" ml="auto">
                    <Link
                      to={`${props.productBasePath}/${assetToLink[a.asset_type]}/${a.id}`}
                      style={{ display: 'inline-flex', color: 'inherit' }}
                    >
                      <OpenInNewIcon color="inherit" />
                    </Link>
                  </Box>
                </Box>
                {i < props.connected_assets.length - 1 && <Divider />}
              </Box>
            );
          })
        ) : (
          <Box display="flex" alignItems="center" flexDirection="row">
            <Box mr="12px">
              <Avatar className={classes.avatar}>
                <TrackChangesIcon color="inherit" />
              </Avatar>
            </Box>
            <Typography variant="body1" color="textPrimary">
              This asset doesn&apos;t seem to be connected to any other assets
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
}

export default memo(ConnectedAssets);
