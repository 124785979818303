import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import Text from './Text';

/*
 * Checkbox with secondary text.
 *
 * [ ] Primary Text
 *     Secondary Text
 */
export const AdvancedCheckbox = function AdvancedCheckbox({ ariaLabel, primaryText, secondaryText, ...props }) {
  return (
    <FormControlLabel
      label={
        <span
          style={{
            position: 'relative',
            top: !primaryText || !secondaryText ? 0 : 12,
          }}
        >
          {typeof primaryText === 'string' ? (
            <Text variant="body" style={{ marginTop: 0, marginBottom: 0, margin: 0 }}>
              {primaryText}
            </Text>
          ) : (
            primaryText
          )}
          {typeof secondaryText === 'string' ? (
            <Text variant="bodyS" style={{ marginTop: 0, marginBottom: 0, margin: 0 }}>
              {secondaryText}
            </Text>
          ) : (
            secondaryText
          )}
        </span>
      }
      control={
        <Checkbox
          color="primary"
          inputProps={{
            'aria-label': ariaLabel,
          }}
          {...props}
        />
      }
    />
  );
};
