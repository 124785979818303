import immer from 'immer';
import {
  hackExtractForbidden,
  hackExtractHideResultsClicked,
  hackExtractMounted,
  hackExtractShowResultsClicked,
  hackExtractStartClicked,
  hackExtractUnmounted,
} from './actions';

const initialState = {
  isViewingHackAndExtract: false,
  showingResults: false,
  isForbidden: false,
};

export default immer((draft, action) => {
  if (action.type === hackExtractShowResultsClicked.toString()) {
    draft.showingResults = true;
  }

  if (action.type === hackExtractStartClicked.toString()) {
    draft.showingResults = false;
    draft.isForbidden = false;
  }

  if (action.type === hackExtractHideResultsClicked.toString()) {
    draft.showingResults = false;
  }

  if (action.type === hackExtractMounted.toString()) {
    draft.isViewingHackAndExtract = true;
  }

  if (action.type === hackExtractUnmounted.toString()) {
    draft.isViewingHackAndExtract = false;
  }

  if (action.type === hackExtractForbidden.toString()) {
    draft.isForbidden = true;
  }
}, initialState);
