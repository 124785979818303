import immer from 'immer';
import {
  assetGroupDeleteClicked,
  assetGroupsCreate,
  assetGroupsCreateDone,
  assetGroupsCreateError,
  assetGroupsCreateSuccess,
  assetGroupsReceived,
} from './actions';
import MembershipsReducer, { initialState as initialStateMembership } from './memberships/reducer';

const initialState = {
  ids: {},
  for_policy_id: {},
  memberships: initialStateMembership,
  create: {
    isCreating: false,
    isCreatingError: false,
    newlyCreatedId: null,
  },
};

export default immer((draft, action) => {
  // $FlowFixMe Not sure what to do
  const newMembershipState = MembershipsReducer(draft.memberships, action);
  if (newMembershipState !== draft.memberships) {
    draft.memberships = newMembershipState;
  }

  switch (action.type) {
    case assetGroupsReceived.toString(): {
      action.payload.forEach(asset => {
        draft.ids[asset.id] = asset;
        const list = draft.for_policy_id[asset.attached_policy_id] || [];

        draft.for_policy_id[asset.attached_policy_id] = list.filter(id => id !== asset.id);

        draft.for_policy_id[asset.attached_policy_id].push(asset.id);
      });
      return;
    }
    case assetGroupsCreate.toString(): {
      draft.create.isCreating = true;
      draft.create.isCreatingError = false;
      draft.create.newlyCreatedId = null;
      return;
    }
    case assetGroupsCreateSuccess.toString(): {
      draft.create.isCreating = false;
      draft.create.isCreatingError = false;
      draft.create.newlyCreatedId = action.payload;
      return;
    }
    case assetGroupsCreateError.toString(): {
      draft.create.isCreating = false;
      draft.create.isCreatingError = false;
      draft.create.newlyCreatedId = action.payload;
      return;
    }
    case assetGroupsCreateDone.toString(): {
      draft.create.isCreating = false;
      draft.create.isCreatingError = false;
      draft.create.newlyCreatedId = null;
      return;
    }

    case assetGroupDeleteClicked.toString(): {
      const asset_group = draft.ids[action.payload];

      if (!asset_group) {
        // already deleted?
        return;
      }

      draft.for_policy_id[asset_group.attached_policy_id] = (
        draft.for_policy_id[asset_group.attached_policy_id] || []
      ).filter(id => id !== action.payload);

      draft.ids[action.payload] = void 0;

      return;
    }
  }
}, initialState);
