import all_communication_encrypted from './all_communication_encrypted.svg';
import does_not_offload_data_to_third_parties from './does_not_offload_data_to_third_parties.svg';
import enhances_password_security from './enhances_password_security.svg';
import fallback from './fallback.svg';
import full_protection_on_untrusted_networks from './full_protection_on_untrusted_networks.svg';
import leverages_secure_browser_settings from './leverages_secure_browser_settings.svg';
import leverages_secure_system_libraries from './leverages_secure_system_libraries.svg';
import leverages_secure_system_sdk from './leverages_secure_system_sdk.svg';
import leverages_secure_system_settings from './leverages_secure_system_settings.svg';
import protects_data_on_screens from './protects_data_on_screens.svg';
import protects_the_keyboard from './protects_the_keyboard.svg';
import removes_data_from_shared_device_locations from './removes_data_from_shared_device_locations.svg';
import requires_android_device_security from './requires_android_device_security.svg';
import requires_android_malware_protection from './requires_android_malware_protection.svg';
import server_enforces_https from './server_enforces_https.svg';
import supports_two_factor_authentication from './supports_two_factor_authentication.svg';

const appProtectionBadgeIcons = {
  all_communication_encrypted: all_communication_encrypted,
  supports_two_factor_authentication: supports_two_factor_authentication,
  full_protection_on_untrusted_networks: full_protection_on_untrusted_networks,
  protects_data_on_screens: protects_data_on_screens,
  requires_android_device_security: requires_android_device_security,
  requires_android_malware_protection: requires_android_malware_protection,
  server_enforces_https: server_enforces_https,
  removes_data_from_shared_device_locations: removes_data_from_shared_device_locations,
  protects_the_keyboard: protects_the_keyboard,
  leverages_secure_system_sdk: leverages_secure_system_sdk,
  leverages_secure_browser_settings: leverages_secure_browser_settings,
  leverages_secure_system_libraries: leverages_secure_system_libraries,
  leverages_secure_system_settings: leverages_secure_system_settings,
  enhances_password_security: enhances_password_security,
  does_not_offload_data_to_third_parties: does_not_offload_data_to_third_parties,
  fallback: fallback,
};

export default appProtectionBadgeIcons;
