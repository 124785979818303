import React, { PureComponent } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { isClosedStatus, isNeutralStatus, statusToFriendlyString } from '@dt/findings/targets/status';
import { palette } from '@dt/theme';
import Indicator from './Indicator';

export default class TargetStatus extends PureComponent {
  render() {
    const { status, loading, onClick } = this.props;

    return (
      <Indicator
        label={
          loading ? (
            <CircularProgress size={14} thickness={2} style={{ marginTop: 2 }} />
          ) : (
            statusToFriendlyString(status)
          )
        }
        onClick={onClick}
        backgroundColor={palette[isNeutralStatus(status) ? 'gray45' : isClosedStatus(status) ? 'green50' : 'red50']}
        color={palette[isNeutralStatus(status) ? 'gray10' : isClosedStatus(status) ? 'green10' : 'red10']}
      />
    );
  }
}
