import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';

const useStyles = makeStyles({
  chip: {
    padding: '0 10px',
    height: 35,
    marginRight: 10,
    borderRadius: 5,
    display: 'inline-flex',
    alignItems: 'center',
    borderColor: palette.gray45,
    borderWidth: 1,
    borderStyle: 'solid',
    '&:hover': {
      backgroundColor: palette.gray40,
    },
  },
});

export const WebApplicationDetectedLibraries = React.memo(({ detected_libraries }) => {
  const classes = useStyles();

  return (
    <div style={{ display: 'inline-block' }}>
      {detected_libraries.length > 0 ? (
        detected_libraries.map(lib => (
          <a
            style={{ display: 'flex' }}
            key={lib.name}
            href={lib.project_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div key={lib.name} className={classes.chip}>
              <img
                src={lib.icon_url}
                style={{
                  width: 25,
                  height: 25,
                  objectFit: 'contain',
                  marginRight: 5,
                }}
              />
              <Typography variant="body1" component="span">
                {lib.name}
              </Typography>
            </div>
          </a>
        ))
      ) : (
        <Typography variant="body2">No technologies detected.</Typography>
      )}
    </div>
  );
});
