import React, { memo } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { Grid } from '@mui/material';
import { palette } from '@dt/theme';
import Text from '../Text';
import { useStyles } from './NotificationItem';

export default memo(function NotificationItemError() {
  const styles = useStyles();

  return (
    <Grid container direction="row" className={styles.errorContainer} spacing={2}>
      <Grid item xs={1}>
        <ErrorIcon style={{ width: 20 }} />
      </Grid>
      <Grid item xs={11}>
        <Text color={palette.gray20} variant="helperText">
          Unable to fetch events. Try again
        </Text>
      </Grid>
    </Grid>
  );
});
