import { useMutation } from '@apollo/client';
import policy_rules, { createPolicyRuleConfig } from '@dt/graphql-support/horizon/policy_rules';

/*
 * Mutation wrapper for toggling a policy rule type's policy rule.
 *
 * Wraps:
 * - `createPolicyRule`: Creates a policy rule for the provided policy & policy rule type.
 * - `removePolicyRule`: Removes a policy rule fro the provided policy & policy rule type.
 */
export const useTogglePolicyRule = () => {
  const [createPolicyRule, { error: policyRulesCreateError, loading: policyRulesCreateLoading }] = useMutation(
    policy_rules.create,
  );

  const [removePolicyRule, { error: policyRulesRemoveError, loading: policyRulesRemoveLoading }] = useMutation(
    policy_rules.remove,
  );

  /*
   * Toggle policy rule by creating one when one doesn't exist and deleting one when one does.
   */
  const togglePolicyRule = async (policy_id, policy_rule_type) => {
    if (policy_rule_type.policy_rule) {
      // Remove policy rule.
      const result = await removePolicyRule({
        variables: {
          policyId: policy_id,
          ruleId: policy_rule_type.policy_rule.id,
        },
        update: (cache, result) => {
          // Skip if there are errors.
          if (!result || result.errors) {
            return;
          }

          // Should never happen since we're removing an existing policy rule here.
          if (!policy_rule_type.policy_rule) {
            return;
          }

          // Remove corresponding referenced policy rule.
          cache.modify({
            fields: {
              policies_policy_rule_types_list(existing_policy_rule_types_list, { readField }) {
                for (const existing_policy_rule_type of existing_policy_rule_types_list.policy_rule_types) {
                  const existing_policy_rule_type_id = readField('id', existing_policy_rule_type);
                  if (policy_rule_type.id === existing_policy_rule_type_id) {
                    // Create updated cache "reference".
                    cache.modify({
                      id: cache.identify(existing_policy_rule_type),
                      fields: {
                        policy_rule() {
                          return null;
                        },
                      },
                    });
                  }
                }

                return existing_policy_rule_types_list;
              },
            },
          });

          // Cleanup cache.
          cache.gc();
        },
      });

      return { response: 'removed', ...result };
    } else {
      // Create policy rule.
      const config = createPolicyRuleConfig(policy_rule_type.configuration_default_value);
      const result = await createPolicyRule({
        variables: {
          id: policy_id,
          body: {
            policy_rule_type_id: policy_rule_type.id,
            relevance: policy_rule_type.default_relevance,
            ...(config && { configuration: config }),
          },
        },
        update: (cache, result) => {
          // Skip if there are errors.
          if (!result || !result.data || result.errors) {
            return;
          }

          // Create corresponding policy rule.
          cache.modify({
            fields: {
              policies_policy_rule_types_list(existing_policy_rule_types_list, { readField }) {
                for (const existing_policy_rule_type of existing_policy_rule_types_list.policy_rule_types) {
                  const existing_policy_rule_type_id = readField('id', existing_policy_rule_type);
                  if (policy_rule_type.id === existing_policy_rule_type_id) {
                    // Create updated cache "reference".
                    cache.modify({
                      id: cache.identify(existing_policy_rule_type),
                      fields: {
                        policy_rule() {
                          return result.data.policy_rules_create;
                        },
                      },
                    });
                  }
                }

                return existing_policy_rule_types_list;
              },
            },
          });

          // Cleanup cache.
          cache.gc();
        },
      });

      return { response: 'created', ...result };
    }
  };

  return [
    togglePolicyRule,
    {
      policyRulesCreateError,
      policyRulesRemoveError,
      policyRulesCreateLoading,
      policyRulesRemoveLoading,
    },
  ];
};
