import { HostedOnEnum } from '@dt/horizon-api';
import { decorate as decorateApiOperation } from '../api_operations/util';
import { AssetTypeDict } from '../inventory/types';
import {
  getAggregatedRelevance,
  getHistoricAggregatedRelevance,
  getUnresolvedPolicyViolationPointValue,
} from '../policy_violations/util';

export const restfulApis = ({ restful_apis }) => restful_apis;

// Returns RestfulAPIDecorated or null if there was no matching web endpoint
// (or if the web point itself didn't have a matching domain name)
export function decorate(restful_api, network_services_decorated, openapi_definitions, policy_violations_decorated) {
  const matching_network_service_decorated = network_services_decorated.find(
    ({ id }) => restful_api.network_service_id === id,
  );

  if (!matching_network_service_decorated) {
    return null;
  }

  const openapi_definition = openapi_definitions.find(({ id }) => restful_api.openapi_definition_id === id);

  const api_operations_decorated = restful_api.api_operations
    ? restful_api.api_operations.map(operation =>
        decorateApiOperation(operation, restful_api, policy_violations_decorated),
      )
    : [];

  const api_policy_violations_decorated = api_operations_decorated.flatMap(
    operation => operation.policy_violations_decorated,
  );

  return {
    ...restful_api,
    asset_type: AssetTypeDict.RESTFUL_API,
    name: restful_api.title,
    url: restful_api.base_url,
    hosted_on: matching_network_service_decorated.hosted_on || HostedOnEnum.UNKNOWN,
    network_service_decorated: matching_network_service_decorated,
    openapi_definition,
    policy_violations_decorated: api_policy_violations_decorated,
    api_operations_decorated: api_operations_decorated,
    aggregated_relevance: getAggregatedRelevance({
      policy_violations_decorated: api_policy_violations_decorated,
    }),
    historic_aggregated_relevance: getHistoricAggregatedRelevance({
      policy_violations_decorated: api_policy_violations_decorated,
    }),
    unresolved_policy_violations_point_value: getUnresolvedPolicyViolationPointValue({
      policy_violations_decorated: api_policy_violations_decorated,
    }),
  };
}
