import React, { memo } from 'react';
import ImportantWarningIcon from '@mui/icons-material/Flag';
import InformationalProactiveIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { palette } from '@dt/theme';

const MiniRelevanceIcon = ({ relevance }) => {
  const Icon =
    relevance === 'URGENT'
      ? WarningIcon
      : relevance === 'IMPORTANT'
        ? ImportantWarningIcon
        : InformationalProactiveIcon;
  const backgroundColor =
    relevance === 'URGENT' ? palette.red50 : relevance === 'IMPORTANT' ? palette.yellow50 : palette.blue50;
  const iconColor = relevance === 'URGENT' ? palette.red : relevance === 'IMPORTANT' ? palette.yellow : palette.blue;

  return (
    <div
      style={{
        display: 'inline-block',
        backgroundColor,
        borderRadius: 2,
        padding: 4,
        fontSize: 0,
        width: 20,
        height: 20,
      }}
    >
      <Icon style={{ color: iconColor, fontSize: 12 }} fontSize="small" />
    </div>
  );
};

export default memo(MiniRelevanceIcon);
