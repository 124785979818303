import React from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { Text } from '@dt/material-components';

const AssetFilterShadowAssets = ({ value, onChange }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column">
          <FormControlLabel
            label={<Text variant="label">Shadow Assets</Text>}
            control={
              <Checkbox
                color="primary"
                inputProps={{
                  'aria-label': `Shadow Assets`,
                }}
                checked={value}
                onChange={e => {
                  onChange(e.target.checked);
                }}
              />
            }
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default AssetFilterShadowAssets;
