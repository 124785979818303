import React, { memo } from 'react';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import { Card, CardContent, Grid } from '@mui/material';
import { EmptyState } from '@dt/components';
import PolicyViolationCard from './PolicyViolationCard';

const PolicyViolationsList = ({ policy_violations, productBasePath }) => (
  <Grid container spacing={2}>
    {!policy_violations.length ? (
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <EmptyState heading={'There are no policy violations right now'} icon={<TrackChangesIcon />}>
              This resource has no policy violations, but once Data Theorem
              {"'"}s analyzer finds one it will show up here.
            </EmptyState>
          </CardContent>
        </Card>
      </Grid>
    ) : (
      <>
        {policy_violations.map((policy_violation, i) => (
          <Grid item xs={12} key={i}>
            <PolicyViolationCard productBasePath={productBasePath} policy_violation={policy_violation} />
          </Grid>
        ))}
      </>
    )}
  </Grid>
);

export default memo(PolicyViolationsList);
