import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';
import { Link } from '@reach/router';

// Things to note when designing for empty state:
// 1. Have some graphic that makes the situation less disappointing. Talk with a fun personality if you can.
// 2. Avoid user-unfriendly words like " cannot" "unable" "fail 😱" "error 😱" "wrong 😱" .
// 3. Be reasonable. Explain possible reasons.
// 4. Be helpful. Suggest solutions.
// 5. Be straightforward. Give one and only one button that most likely can get out from here.

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    justifyContent: props => (props.leftAligned ? 'flex-start' : 'center'),
  },
  paragraph: {
    margin: '15px 0 !important',
  },
  icon: {
    width: props => (props.inline ? 120 : 200),
    height: props => (props.inline ? 120 : 200),
    background: palette.gray45,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 120,
    flexGrow: 0,
    flexShrink: 0,
  },
});

export default React.memo(function EmptyState({ children, ...props }) {
  const classes = useStyles(props);
  const childrens = React.Children.toArray(children);

  const actionButton = childrens.filter(e => e.type && e.type === EmptyStateAction);

  const paragraph = childrens.filter(e => (e.type ? e.type !== EmptyStateAction : true));

  return (
    <div className={classes.container}>
      <div>
        <>
          {props.heading && (
            <Typography variant="h5" style={{ color: palette.gray20 }}>
              {props.heading}
            </Typography>
          )}
        </>
        <Typography component="div" variant="body2" className={classes.paragraph}>
          {paragraph}
        </Typography>
        <div>{actionButton}</div>
      </div>
      {props.icon && (
        <div className={classes.icon}>
          {React.cloneElement(props.icon, {
            style: {
              color: palette.gray30,
              width: props.inline ? 70 : 120,
              height: props.inline ? 70 : 120,
            },
          })}
        </div>
      )}
    </div>
  );
});

export function EmptyStateAction(props) {
  const button = (
    <Button
      onClick={props.onClick}
      variant={props.secondary ? 'text' : 'contained'}
      aria-label={`Move on from Empty State`}
    >
      {props.children}
    </Button>
  );

  if (props.to) {
    return <Link to={props.to}>{button}</Link>;
  }
  return button;
}
