export const reminderContents = {
  title: {
    '60_days': 'Your subscription will expire soon.',
    '30_days': 'Your subscription will expire very soon.',
    '15_days': 'Your subscription will expire in less than a month.',
    today: 'Your subscription expires today.',
    beforeExp: 'Your Data Theorem subscription expires in {number} days.',
    afterExp: 'Your Data Theorem subscription expired {number} days ago!',
  },
  quote_url: 'View your PO Quote here',
  desc: {
    beforeExp: {
      plural: 'Please complete one of the following actions to avoid disruption of your service.',
      singular: 'Please complete the following action to avoid disruption of your service',
    },
    afterExp: {
      p1: 'Due to compliance reasons, we must wipe your data soon.',
    },
  },
  buttons: {
    notifyBilling: 'Notify billing contact',
    payByCard: 'Pay Now',
    uploadPo: 'Upload PO',
  },
};

export const notificationContents = {
  billingUser: `Thank you for submitting your subscription information. 
    We will update your subscription status once your subscription has been processed.`,
  nonBillingUser: 'Your billing contact has been notified.',
};
