import React from 'react';
import LaunchIcon from '@mui/icons-material/Launch';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Grid, Tooltip, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';

const useStyles = makeStyles({
  row: {
    backgroundColor: palette.gray45,
  },
  cell: {
    padding: 8,
  },
  unprotectedContainer: {
    display: 'flex',
    width: 140,
    padding: 4,
    '& svg': {
      color: palette.red30,
      fontSize: 20,
    },
    '& p': {
      color: palette.red30,
      paddingLeft: 2,
    },
  },
  icon: {
    borderRadius: 100,
    width: 24,
    height: 24,
    marginLeft: 4,
  },
  link: {
    color: palette.gray35,
    display: 'grid',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: 4,
  },
});

function ToolkitAssetTableSelectedRowDetails({ policyViolationId, PolicyRuleType, portalPath }) {
  const classes = useStyles();
  return (
    <TableRow classes={{ root: classes.row }}>
      <TableCell colSpan={4} classes={{ root: classes.cell }}>
        <Grid container alignItems={'center'}>
          <Grid item>
            <Tooltip
              disableInteractive
              title={PolicyRuleType.description}
              aria-label={PolicyRuleType.description}
              placement="bottom"
            >
              <Typography>{PolicyRuleType.title}</Typography>
            </Tooltip>
          </Grid>

          <Grid item>
            <div className={classes.unprotectedContainer}>
              <Typography className={classes.icon}>
                <ReportProblemIcon />
              </Typography>
              <Typography>Unprotected</Typography>
            </div>
          </Grid>
          <Grid item>
            <a
              className={classes.link}
              href={`${portalPath}/policy-violations/${policyViolationId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Grid container alignItems={'center'} spacing={1}>
                <Grid item>
                  <Typography>More Details</Typography>
                </Grid>
                <Grid item>
                  <LaunchIcon />
                </Grid>
              </Grid>
            </a>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
}

export default React.memo(ToolkitAssetTableSelectedRowDetails);
