import React from 'react';
import { PolicyViolationStatusEnumValues } from '@dt/graphql-support/enums';
import { Tag } from '@dt/material-components';
import { palette } from '@dt/theme';

const StatusLookup = {
  [PolicyViolationStatusEnumValues.OPEN]: {
    label: 'OPEN',
    bgColor: palette.red50,
    fgColor: palette.red10,
  },
  [PolicyViolationStatusEnumValues.RESOLVED]: {
    label: 'FIXED',
    bgColor: palette.green50,
    fgColor: palette.green10,
  },
  [PolicyViolationStatusEnumValues.DELETED]: {
    label: 'DELETED',
    bgColor: palette.red50,
    fgColor: palette.red10,
  },
  [PolicyViolationStatusEnumValues.WONT_FIX]: {
    label: 'CLOSED',
    bgColor: palette.gray45,
    fgColor: palette.gray20,
  },
};

/*
 * Policy violations status tag used to show the state of a policy violation.
 * - OPEN
 * - DELETED
 * - RESOLVED
 * - WONT FIX
 */
const PolicyViolationsStatusTagComponent = function PolicyViolationsStatusTag({ status }) {
  // Lookup status tag info, fallback to unknown otherwise.
  const { label, bgColor, fgColor } = StatusLookup[status] || {
    label: 'Unknown',
    bgColor: palette.gray45,
    fgColor: palette.black,
  };

  return <Tag label={label} backgroundColor={bgColor} color={fgColor} />;
};

export const PolicyViolationsStatusTag = PolicyViolationsStatusTagComponent;
