import { array, object, string } from 'yup';

export const TargetStatusSchema = object().shape({
  status: string().required(),
  date: string().required(),
});

const TargetAdditionalContextSchema = object().shape({
  type: string().required(),
  body: string().required(),
});

export const TargetSchema = object().shape({
  id: string().required(),
  formatted_text: string().required(),
  statuses: array().of(TargetStatusSchema).required(),
  additional_contexts: array().of(TargetAdditionalContextSchema),
});
