import { default as MobileAppPlatformEnum } from '@dt/enums/MobileAppPlatformEnum';
import { intersection } from 'lodash/fp';
import numeral from 'numeral';

export const DecisionMetricPolicyViolationsEnum = {
  threshold_critical_security_issues_count: 'threshold_critical_security_issues_count',
  threshold_google_play_blocker_count: 'threshold_google_play_blocker_count',
  threshold_apple_app_store_blocker_count: 'threshold_apple_app_store_blocker_count',
  threshold_sdk_with_issues_count: 'threshold_sdk_with_issues_count',
  threshold_low_severity_issue_count: 'threshold_low_severity_issue_count',
  threshold_medium_severity_issues_count: 'threshold_medium_severity_issues_count',
  threshold_high_severity_issues_count: 'threshold_high_severity_issues_count',
};

export const SecurityMetadataPolicyViolationsEnum = {
  data_at_rest_exposure: 'data_at_rest_exposure',
  has_android_permissions: 'has_android_permissions',
  has_ios_permissions: 'has_ios_permissions',
  communicates_with_countries_outside_us: 'communicates_with_countries_outside_us',
  minimum_ios_sdk_version: 'minimum_ios_sdk_version',
  minimum_android_sdk_version: 'minimum_android_sdk_version',
};

export const GeneralMetricPolicyViolationsEnum = {
  threshold_sdk_count: 'threshold_sdk_count',
  supports_apple_watch: 'supports_apple_watch',
  supports_imessage: 'supports_imessage',
  supports_face_id: 'supports_face_id',
};

export const ProtectionMetricPolicyViolationsEnum = {
  minimum_app_protection_score: 'minimum_app_protection_score',
  lacks_does_not_offload_data_to_third_parties: 'lacks_does_not_offload_data_to_third_parties',
  lacks_enhances_password_security: 'lacks_enhances_password_security',
  lacks_leverages_secure_browser_settings: 'lacks_leverages_secure_browser_settings',
  lacks_leverages_secure_system_libraries: 'lacks_leverages_secure_system_libraries',
  lacks_leverages_secure_system_sdk: 'lacks_leverages_secure_system_sdk',
  lacks_leverages_secure_system_settings: 'lacks_leverages_secure_system_settings',
  lacks_protects_the_keyboard: 'lacks_protects_the_keyboard',
  lacks_removes_data_from_shared_device_locations: 'lacks_removes_data_from_shared_device_locations',
  lacks_all_communication_encrypted: 'lacks_all_communication_encrypted',
  lacks_supports_two_factor_authentication: 'lacks_supports_two_factor_authentication',
  lacks_full_protection_on_untrusted_networks: 'lacks_full_protection_on_untrusted_networks',
  lacks_protects_data_on_screens: 'lacks_protects_data_on_screens',
  lacks_requires_android_device_security: 'lacks_requires_android_device_security',
  lacks_requires_android_malware_protection: 'lacks_requires_android_malware_protection',
  lacks_server_enforces_https: 'lacks_server_enforces_https',
};

export const PolicyViolationsEnum = {
  // TODO@nw: So FlowJs doesn't have very good spread support but spreading the categorical enum sets causes a lot of
  //          really annoying issues. When this is fixed we should be spreading these instead of duplicating them.
  // ...DecisionMetricPolicyViolationsEnum,
  // ...SecurityMetadataPolicyViolationsEnum,
  // ...GeneralMetricPolicyViolationsEnum,
  // ...ProtectionMetricPolicyViolationsEnum,
  threshold_critical_security_issues_count: 'threshold_critical_security_issues_count',
  threshold_google_play_blocker_count: 'threshold_google_play_blocker_count',
  threshold_apple_app_store_blocker_count: 'threshold_apple_app_store_blocker_count',
  threshold_sdk_with_issues_count: 'threshold_sdk_with_issues_count',
  threshold_low_severity_issue_count: 'threshold_low_severity_issue_count',
  threshold_medium_severity_issues_count: 'threshold_medium_severity_issues_count',
  threshold_high_severity_issues_count: 'threshold_high_severity_issues_count',
  data_at_rest_exposure: 'data_at_rest_exposure',
  has_android_permissions: 'has_android_permissions',
  has_ios_permissions: 'has_ios_permissions',
  communicates_with_countries_outside_us: 'communicates_with_countries_outside_us',
  minimum_ios_sdk_version: 'minimum_ios_sdk_version',
  minimum_android_sdk_version: 'minimum_android_sdk_version',
  threshold_sdk_count: 'threshold_sdk_count',
  supports_apple_watch: 'supports_apple_watch',
  supports_imessage: 'supports_imessage',
  supports_face_id: 'supports_face_id',
  minimum_app_protection_score: 'minimum_app_protection_score',
  lacks_does_not_offload_data_to_third_parties: 'lacks_does_not_offload_data_to_third_parties',
  lacks_enhances_password_security: 'lacks_enhances_password_security',
  lacks_leverages_secure_browser_settings: 'lacks_leverages_secure_browser_settings',
  lacks_leverages_secure_system_libraries: 'lacks_leverages_secure_system_libraries',
  lacks_leverages_secure_system_sdk: 'lacks_leverages_secure_system_sdk',
  lacks_leverages_secure_system_settings: 'lacks_leverages_secure_system_settings',
  lacks_protects_the_keyboard: 'lacks_protects_the_keyboard',
  lacks_removes_data_from_shared_device_locations: 'lacks_removes_data_from_shared_device_locations',
  lacks_all_communication_encrypted: 'lacks_all_communication_encrypted',
  lacks_supports_two_factor_authentication: 'lacks_supports_two_factor_authentication',
  lacks_full_protection_on_untrusted_networks: 'lacks_full_protection_on_untrusted_networks',
  lacks_protects_data_on_screens: 'lacks_protects_data_on_screens',
  lacks_requires_android_device_security: 'lacks_requires_android_device_security',
  lacks_requires_android_malware_protection: 'lacks_requires_android_malware_protection',
  lacks_server_enforces_https: 'lacks_server_enforces_https',
};

export const PolicyViolationsLookup = {
  [PolicyViolationsEnum.threshold_critical_security_issues_count]: (
    app,
    config, // eslint-disable-line
  ) => !!app.security_metrics && numeral(app.security_metrics.open_security_finding_security_p1_count).value() > 0,

  [PolicyViolationsEnum.threshold_google_play_blocker_count]: (app, config) =>
    !!app.security_metrics &&
    !!config.threshold_google_play_blocker_count &&
    app.platform === MobileAppPlatformEnum.ANDROID &&
    numeral(app.security_metrics.open_security_finding_google_play_blocker_count).value() >
      numeral(config.threshold_google_play_blocker_count).value(),
  [PolicyViolationsEnum.threshold_apple_app_store_blocker_count]: (app, config) =>
    !!app.security_metrics &&
    !!config.threshold_apple_app_store_blocker_count &&
    app.platform === MobileAppPlatformEnum.IOS &&
    numeral(app.security_metrics.open_security_finding_apple_app_store_blocker_count).value() >
      numeral(config.threshold_apple_app_store_blocker_count).value(),

  [PolicyViolationsEnum.threshold_sdk_with_issues_count]: (app, config) =>
    !!app.security_metrics &&
    !!config.threshold_sdk_with_issues_count &&
    numeral(app.security_metrics.sdk_with_issue_count).value() >
      numeral(config.threshold_sdk_with_issues_count).value(),

  [PolicyViolationsEnum.threshold_low_severity_issue_count]: (app, config) =>
    !!app.security_metrics &&
    !!config.threshold_low_severity_issue_count &&
    numeral(app.security_metrics.open_security_finding_low_severity_count).value() >
      numeral(config.threshold_low_severity_issue_count).value(),
  [PolicyViolationsEnum.threshold_medium_severity_issues_count]: (app, config) =>
    !!app.security_metrics &&
    !!config.threshold_medium_severity_issues_count &&
    numeral(app.security_metrics.open_security_finding_medium_severity_count).value() >
      numeral(config.threshold_medium_severity_issues_count).value(),
  [PolicyViolationsEnum.threshold_high_severity_issues_count]: (app, config) =>
    !!app.security_metrics &&
    !!config.threshold_high_severity_issues_count &&
    numeral(app.security_metrics.open_security_finding_high_severity_count).value() >
      numeral(config.threshold_high_severity_issues_count).value(),

  [PolicyViolationsEnum.minimum_app_protection_score]: (app, config) =>
    typeof app.app_protection_score_ratio !== 'undefined' &&
    !!config.minimum_app_protection_score &&
    numeral(app.app_protection_score_ratio).value() * 100 < numeral(config.minimum_app_protection_score).value(),

  [PolicyViolationsEnum.lacks_does_not_offload_data_to_third_parties]: (app, config) =>
    !!config.lacks_does_not_offload_data_to_third_parties &&
    app.platform === MobileAppPlatformEnum.ANDROID &&
    !app.app_protection_metrics.does_not_offload_data_to_third_parties,

  [PolicyViolationsEnum.lacks_enhances_password_security]: (app, config) =>
    !!config.lacks_enhances_password_security &&
    app.platform === MobileAppPlatformEnum.IOS &&
    !app.app_protection_metrics.enhances_password_security,

  [PolicyViolationsEnum.lacks_leverages_secure_browser_settings]: (app, config) =>
    !!config.lacks_leverages_secure_browser_settings && !app.app_protection_metrics.leverages_secure_browser_settings,

  [PolicyViolationsEnum.lacks_leverages_secure_system_libraries]: (app, config) =>
    !!config.lacks_leverages_secure_system_libraries &&
    app.platform === MobileAppPlatformEnum.IOS &&
    !app.app_protection_metrics.leverages_secure_system_libraries,

  [PolicyViolationsEnum.lacks_leverages_secure_system_sdk]: (app, config) =>
    !!config.lacks_leverages_secure_system_sdk && !app.app_protection_metrics.leverages_secure_system_sdk,

  [PolicyViolationsEnum.lacks_leverages_secure_system_settings]: (app, config) =>
    !!config.lacks_leverages_secure_system_settings &&
    app.platform === MobileAppPlatformEnum.ANDROID &&
    !app.app_protection_metrics.leverages_secure_system_settings,

  [PolicyViolationsEnum.lacks_protects_the_keyboard]: (app, config) =>
    !!config.lacks_protects_the_keyboard &&
    app.platform === MobileAppPlatformEnum.IOS &&
    !app.app_protection_metrics.protects_the_keyboard,

  [PolicyViolationsEnum.lacks_removes_data_from_shared_device_locations]: (app, config) =>
    !!config.lacks_removes_data_from_shared_device_locations &&
    app.platform === MobileAppPlatformEnum.IOS &&
    !app.app_protection_metrics.removes_data_from_shared_device_locations,

  [PolicyViolationsEnum.lacks_all_communication_encrypted]: (app, config) =>
    !!config.lacks_all_communication_encrypted && !app.app_protection_metrics.all_communication_encrypted,

  [PolicyViolationsEnum.lacks_supports_two_factor_authentication]: (app, config) =>
    !!config.lacks_supports_two_factor_authentication &&
    app.platform === MobileAppPlatformEnum.IOS &&
    !app.app_protection_metrics.supports_two_factor_authentication,

  [PolicyViolationsEnum.lacks_full_protection_on_untrusted_networks]: (app, config) =>
    !!config.lacks_full_protection_on_untrusted_networks &&
    !app.app_protection_metrics.full_protection_on_untrusted_networks,

  [PolicyViolationsEnum.lacks_protects_data_on_screens]: (app, config) =>
    !!config.lacks_protects_data_on_screens &&
    app.platform === MobileAppPlatformEnum.ANDROID &&
    !app.app_protection_metrics.protects_data_on_screens,

  [PolicyViolationsEnum.lacks_requires_android_device_security]: (app, config) =>
    !!config.lacks_requires_android_device_security &&
    app.platform === MobileAppPlatformEnum.ANDROID &&
    !app.app_protection_metrics.requires_android_device_security,

  [PolicyViolationsEnum.lacks_requires_android_malware_protection]: (app, config) =>
    !!config.lacks_requires_android_malware_protection &&
    app.platform === MobileAppPlatformEnum.ANDROID &&
    !app.app_protection_metrics.requires_android_malware_protection,

  [PolicyViolationsEnum.lacks_server_enforces_https]: (app, config) =>
    !!config.lacks_server_enforces_https && !app.app_protection_metrics.server_enforces_https,

  [PolicyViolationsEnum.threshold_sdk_count]: (app, config) =>
    !!config.threshold_sdk_count &&
    numeral(app.insights.sdk_count).value() > numeral(config.threshold_sdk_count).value(),

  [PolicyViolationsEnum.data_at_rest_exposure]: (app, config) =>
    !!config.data_at_rest_exposure &&
    typeof app.insights.data_at_rest_exposure !== 'undefined' &&
    app.insights.data_at_rest_exposure != null &&
    app.insights.data_at_rest_exposure,

  [PolicyViolationsEnum.supports_apple_watch]: (app, config) =>
    !!config.supports_apple_watch &&
    app.platform === MobileAppPlatformEnum.IOS &&
    app.insights.ios_supports_apple_watch,

  [PolicyViolationsEnum.supports_imessage]: (app, config) =>
    !!config.supports_imessage && app.platform === MobileAppPlatformEnum.IOS && app.insights.ios_supports_imessage,

  [PolicyViolationsEnum.supports_face_id]: (app, config) =>
    !!config.supports_face_id && app.platform === MobileAppPlatformEnum.IOS && app.insights.ios_supports_face_id,

  [PolicyViolationsEnum.has_android_permissions]: (app, config) =>
    !!config.has_android_permissions &&
    app.platform === MobileAppPlatformEnum.ANDROID &&
    intersection(config.has_android_permissions || [], app.insights.android_permissions || []).length > 0,

  [PolicyViolationsEnum.has_ios_permissions]: (app, config) =>
    !!config.has_ios_permissions &&
    app.platform === MobileAppPlatformEnum.IOS &&
    intersection(config.has_ios_permissions || [], app.insights.ios_permissions || []).length > 0,

  [PolicyViolationsEnum.communicates_with_countries_outside_us]: (app, config) =>
    (config.communicates_with_countries || []).includes('OUTSIDE_US') &&
    (app.insights.communicates_with_countries || []).filter(country => country !== 'US').length > 0,

  [PolicyViolationsEnum.minimum_ios_sdk_version]: (app, config) =>
    !!config.minimum_ios_sdk_version &&
    app.platform === MobileAppPlatformEnum.IOS &&
    numeral(app.insights.ios_minimum_sdk).value() < numeral(config.minimum_ios_sdk_version).value(),

  [PolicyViolationsEnum.minimum_android_sdk_version]: (app, config) =>
    !!config.minimum_android_sdk_version &&
    app.platform === MobileAppPlatformEnum.ANDROID &&
    numeral(app.insights.android_minimum_sdk_version).value() < numeral(config.minimum_android_sdk_version).value(),

  // TODO@nw: SASB-958 - The backend does not have corresponding fields to these configuration options.
  //            - threshold_days_since_last_release
  //            - threshold_binary_size
};
