const statusObject = {
  OPEN: 'OPEN',
  NEW: 'NEW',
  CLOSED_BY_POLICY: 'CLOSED_BY_POLICY',
  CLOSED_FIXED: 'CLOSED_FIXED',
  CLOSED_RISK_ACCEPTED: 'CLOSED_RISK_ACCEPTED',
  CLOSED_COMPENSATING_CONTROL: 'CLOSED_COMPENSATING_CONTROL',
  CLOSED_ITEM_NOT_FOUND: 'CLOSED_ITEM_NOT_FOUND',
  OPEN_READY_TO_RESCAN: 'OPEN_READY_TO_RESCAN',
};

export default statusObject;
