import createReducerCreator from 'rezz/createReducerCreator';
import removeFromList from 'rezz/removeFromList';
import updateInList from 'rezz/updateInList';
import { apiKeyDeleteResponseReceived, apiKeysReceived } from '../actions';

// $FlowFixMe FlowUpgrade
export default createReducerCreator({
  initialState: [],
  equalityCheck: (a, b) => a.id === b.id,
})(updateInList(apiKeysReceived.toString()), removeFromList(apiKeyDeleteResponseReceived.toString()));
