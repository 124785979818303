import React, { memo } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  content: {
    width: '60%',
    minWidth: '200px',
  },
});

function DrawerRight({ open, onClose, children }) {
  const css = useStyles();
  return (
    <Drawer anchor="right" open={open} onClose={onClose} classes={{ paper: css.content }}>
      <Box padding="16px" height="100%">
        {children}
      </Box>
    </Drawer>
  );
}

export default memo(DrawerRight);
